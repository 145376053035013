import React from 'react'
import M from 'materialize-css'

export default function Input({ label, icon, ...props }) {

    React.useEffect(function () {
        M.updateTextFields()
        return function () { }
        // eslint-disable-next-line
    }, [])

    return (
        <div >
            <label className='active' htmlFor="icon_prefix">{label}</label>
            <i className="material-icons left">{icon}</i>
            <input {...props} />
        </div>
    )
}