import React, { Component } from 'react'
import image4 from './Images/image4.jpg'

export default class Ring extends Component {
    componentDidMount() {
        document.title = 'Bakongwa Doctors'
    }

    
    render() {
        return (
            <section>
                <React.Fragment>
                    <div className="row spell" style={{ borderBottom: "1px", borderColor: "green" }}>
                        <div className="col l6 m12 s12">
                            <h4>MAGIC RINGS</h4>
                            <p>
                                This is the oldest powerful mystic ring organized by great magicians from the early (150-800BC) and it was made to be for the pharaohs ,prophets , goddess of Egypt it has got powers from the Angels of the 7th planets .
                                Some of the rings Dr. Bakongwa can help you on include: MAGIC RING, WALLET, BUNGLE, EAR RING, STICK AND NECKLACE FOR WEALTH, WIN LOTTO, CASSINO, LOVE ATTRACTION, PROTECTION AND GOOD LUCK FOR LIFE
                            </p>
                        </div>
                        <div className="col l6 m12 s12">
                            <img src={image4} alt="Magic Rings" className="container materialboxed" style={{ width: "100%" }}  />
                        </div>
                    </div>
                </React.Fragment>
            </section>
        )
    }
}