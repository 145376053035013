import React, { Component } from 'react'
import Whatsapp from './components/Whatsaap'
import Navbar from './components/Navbar'
import image91 from './components/Images/image91.jpg'
import Footer from './components/Footer'

export default class LostSolution extends Component {

    componentDidMount() {
        document.title = 'Bakongwa Doctors'
    }


    render() {
        return (
            <React.Fragment>
                <Navbar /><br />
                <Whatsapp />
                <div className="row grey lighten-5 row">
                    <div className="col s10 m10 l10 push-l1 push-s1 offset-m1">
                        <img src={image91} alt="image73" className="materialboxed col s12 m12" />
                        <span className="img-tittle green-text text-darken-2"><strong style={{ fontSize: "25px" }}>THE STRATEGY I USED TO WIN BETS HAS NOW MADE ME A BIG MILLIONAIRE.</strong></span>
                        <p>
                            "In this world, there are many ways to succeed in life, but the challenge is how to discover and utilize those ways to achieve success.

                            Unfortunately, very few successful people are willing to share the true secrets of their success. Most prefer to keep their achievements a secret, fearing competition or unwanted attention.

                            One seemingly easy way to achieve financial success is through sports betting. By placing a small amount of money, you can potentially win a large sum if the selected teams win as predicted. However, many people, especially the youth, find themselves stuck in a cycle of betting for years without making any substantial gains, only benefiting the betting companies and their agents.

                            I was once part of this group, spending my money on betting for over four years without winning. I would see people on TV and in newspapers being announced as winners of millions through betting, and it hurt me deeply. I wondered what strategies others were using to win.

                            Despite continuing to bet, I never experienced any success and ended up depleting all my funds. I even had to sell my belongings, such as my bed, radio, mattress, and kitchen utensils. One morning, while listening to a radio station, I heard about a person named Bakongwa Doctors who claimed to help individuals win in sports betting. The radio host provided his contact number, +243 990 627 777, and I decided to reach out to him.

                            During our conversation, Bakongwa Doctors assured me that within three days, I would win a significant amount through sports betting and forget the struggles I had faced for many years. Three days later, I decided to place a bet on a 13-team jackpot and won. This marked the beginning of bidding farewell to poverty, which had tormented me and my family, relatives, and friends for years.

                            Now, I've been able to build two houses, renting out one while living in the other with my wife. I've also purchased four motorcycles, providing income as young riders bring in money every evening. Without the assistance of Bakongwa Doctors, I wouldn't have achieved all this in my life, and I never expected it.

                            From the bottom of my heart, I would like every young person who has been unsuccessful in sports betting to know about this success secret – Bakongwa Doctors, who has helped many.

                            Remember, Bakongwa Doctors has the ability to bring luck into your life, win in lottery games, protect you from enemies, help you overcome land disputes, enhance your romantic appeal, and increase sales in your business.

                            Additionally, Bakongwa Doctors provides treatment for diseases such as syphilis, gonorrhea, tuberculosis, hypertension, epilepsy, erectile dysfunction, swollen feet, and more. He also facilitates women to conceive and men to achieve fertility."</p> <brv /><br />
                        <strong>Connect with Us:<a href="tel:+243 990 627 777" className="green-text text-darken-2" > Phone : +243 990 627 777</a></strong> | <strong><a href="mailto:bbakongwadoctors@gmail.com" className="green-text text-darken-2" >Email :bbakongwadoctors@gmail.com </a></strong>
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        )
    }
}