import React from 'react'
import M from 'materialize-css'
import Loader from '../../components/loader'
import { getUserInfo, servelURL, date } from '../../config'
import Button from '../../components/button'
import backgroundImage from '../../config/logo.jpg'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'

const BlogView = function (props) {

    const [loading, setLoading] = React.useState(false)
    const [dataObject, setDataObject] = React.useState('')
    const [disabled, setDisabled] = React.useState(false)
    const serverURL = 'https://bakongwadoctors.cyclic.app'

    React.useState(function () {
        if (getUserInfo().role === 'admin') {
            document.title = 'Bakongwa Doctors | Blog View'
            const { blog } = props?.location?.state
            setDataObject(blog)
        }
        else props.history.push('/blog/list')
        M.AutoInit()
        return function () { }
        // eslint-disable-next-line
    }, [])

    async function deleteBlog() {
        try {
            setLoading(true)
            const folder = `blog`
            const fileName = dataObject?.image
            const parameters = `folderName=${folder}&fileName=${fileName}`
            const res = window.confirm(`Are you sure you want to delete ${dataObject.title.toUpperCase()} Blog ?`)
            if(res){
                let response = await fetch(`${servelURL}/delete-file?${parameters}`, {
                    method: 'DELETE',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
     
                response = await response.json()
                if (response.success) {
                    const condition = JSON.stringify({
                        _id: dataObject._id
                    })
                    const schema = 'blog'
                    const parameters = `schema=${schema}&condition=${condition}`
    
                    let response = await fetch(`${servelURL}/delete?${parameters}`, {
                        method: 'DELETE',
                        mode: 'cors',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
    
                    response = await response.json()
                    if (response.success) {
                        setLoading(false)
                        new M.Toast({ html: 'Successfully deleted the blog' })
                        props.history.push({
                            pathname: "/blog/list"
                        })
                    }
                    else {
                        setLoading(false)
                        new M.Toast({ html: response.message })
                    }
                }
                else {
                    setLoading(false)
                    const condition = JSON.stringify({
                        _id: dataObject._id
                    })
                    const schema = 'blog'
                    const parameters = `schema=${schema}&condition=${condition}`
    
                    let response = await fetch(`${servelURL}/delete?${parameters}`, {
                        method: 'DELETE',
                        mode: 'cors',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
    
                    response = await response.json()
                    if (response.success) {
                        setLoading(false)
                        new M.Toast({ html: 'Successfully deleted the blog' })
                        props.history.push({
                            pathname: "/blog/list"
                        })
                    }
                    else {
                        setLoading(false)
                        new M.Toast({ html: response.message })
                    }
                }
            }
            else setLoading(false)
        }
        catch (error) {
            setDisabled(true)
            if (error instanceof Error) {
                new M.Toast({ html: error.message })
            }
            else console.log(error)
        }
    }

    return (
        <main>
            <Navbar />
            {
                loading ? <Loader /> : null
            }
            <div
                className='background-image'
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundSize: 'cover',
                    opacity: 0.2,
                    zIndex: -1,
                }}
            >
            </div>
            <div className='row' style={{ marginTop: "2rem" }}>
                <div className='col s12 m3 push-m2'>
                    <div className='card'>
                        <div className='card-content'>
                            <span className='card-title center'>Basic Info's</span>
                            <div className='row'>
                                <div className='col s12'>
                                    <p className='black-text'><i className='material-icons left'>account_box</i>
                                        Account Role : {getUserInfo().role === 'admin' ? 'Adminstrator ' : 'Customer'}
                                    </p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col s12' >
                                    <p className='black-text'><i className='material-icons left'>device_hub</i>
                                        Blog Title : {dataObject?.title?.toUpperCase()}
                                    </p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col s12' >
                                    <p className='black-text'><i className='material-icons left'>details</i>
                                        Category : {dataObject.category ? dataObject.category?.name.toUpperCase() : 'N/A'}
                                    </p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col s12' >
                                    <p className='black-text'><i className='material-icons left'>update</i>
                                        Created By : {dataObject.created_by === getUserInfo()._id ? getUserInfo().name.toUpperCase() : 'SEBASTIAN IBRAHIM LUGOME'}
                                    </p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col s12' >
                                    <p className='black-text'><i className='material-icons left'>update</i>
                                        Updated By : {dataObject.updated_by === null ? 'N/A' : getUserInfo().name.toUpperCase()}
                                    </p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col s12' >
                                    <p className='black-text'><i className='material-icons left'>av_timer</i>
                                        Created Date : {date(dataObject.createdAt)}
                                    </p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col s12' >
                                    <p className='black-text'><i className='material-icons left'>update</i>
                                        Updated Date : {dataObject.createdAt === dataObject.updatedAt ? 'N/A' : date(dataObject.updatedAt)}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col s12 m5 push-m2'>
                    <div className='card'>
                        <div className='card-content'>
                            <span className='card-title center'>Take Action</span>
                            <div className='center'>
                                {
                                    dataObject?.image ?
                                        <img src={`${serverURL}/uploads/blog/${dataObject?.image}`} alt='pic' className="circle responsive-img" width='20%' />
                                        :
                                        <img src={`${dataObject?.pic}`} className='circle responsive-img' alt='pic' width='20%' />

                                }
                            </div>
                        </div>
                        <div className='card-action center'>
                            <div className='row'>
                                <div className='col s6'>
                                    <Button
                                        className='btn-small light-green accent-3 black-text'
                                        icon='edit'
                                        name='Update'
                                        onClick={() => {
                                            props.history.push({
                                                pathname: '/blog/form',
                                                state: { blog: dataObject }
                                            })
                                        }}
                                    />
                                </div>
                                <div className='col s6'>
                                    <Button
                                        className='btn-small amber lighten-2 black-text '
                                        name='Delete'
                                        icon='delete'
                                        onClick={() => {
                                            deleteBlog(dataObject._id)
                                        }}
                                        disabled={disabled ? true : false}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </main>
    )
}

export default BlogView