import React, { Component } from 'react'
import image5 from './Images/image5.jpg'

export default class Spell extends Component {

     componentDidMount() {
        document.title = 'Bakongwa Doctors'
    }

    
    render() {
        return (
            <React.Fragment>
                <div className="row spell">
                    <div className="col l6 m12 s12">
                        <h4>SPELLS</h4>
                        <p>
                            .You ever feel like broken from your long outstanding-relationship ? Redeem the mistake happened to you take he love spell right now.You need that financial freedom in your life, right? Money spell by Bakongwa Doctors is all you need.
                            This powerful money spell allows you to change the direction of your financial future to better yourself and loved ones.  Actually, you need not to worry about finding money hard, because it will always be coming to your side!
                            Why struggle while we can help you get all you need to pay your bills, sort your daily expenses and even have enough to share with your people
                        </p>
                    </div>
                    <div className="col l6 s12 m12">
                        <img src={image5} alt="Spells" className="container materialboxed" style={{ width: "100%" }}  />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}