import React from 'react';
import ReactHtmlParser from 'react-html-parser';

function DocumentViewer({ document }) {
  if (!document) {
    return null; // or display an appropriate message for when the document is undefined
  }

  return (
    <div>
      {ReactHtmlParser(document)}
    </div>
  );
}

export default DocumentViewer;
