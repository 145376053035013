import React, { Component } from 'react'
import Navbar from './components/Navbar'
import Whatsapp from './components/Whatsaap'
import emailjs from 'emailjs-com'
import Footer from './components/Footer'
import M from 'materialize-css'
import { init } from 'emailjs-com';
init("MD7C4ZGC3jNxr0dg7");

export default class Contactus extends Component {

     componentDidMount() {
        document.title = 'Bakongwa Doctors'
    }

    state = {
        user_name: "",
        user_email: "",
        phone: "",
        location: "",
        problem: "",
        message: "",
        user_nameerror: "",
        user_emailerror: "",
        phoneerror: "",
        locationerror: "",
        problemerror: "",
        messageerror: ""
    }

    Validate = event => {
        var errors = []
        event.preventDefault()
        if (!this.state.user_name) {
            errors.push('')
            this.setState({
                user_nameerror: "this field is required"
            })
        }
        if (!this.state.user_emailerror) {
            errors.push('')
            this.setState({
                user_emailerror: "fhis foeld is required"
            })
        }

        if (!this.state.phoneerror) {
            errors.push("")
            this.setState({
                phoneerror: "this field is required"
            })
        }
        if (!this.state.locationerror) {
            errors.push("")
            this.setState({
                locationerror: "this field is required"
            })
        }
        if (!this.state.problemerror) {
            errors.push("")
            this.setState({
                problemerror: "this field is required"
            })
        }
        if (!this.state.messageerror) {
            errors.push("")
            this.setState({
                messageerror: "this field is required"
            })
        }
        if (errors.length === 0) {
            this.HandleSubmit(event)
        }
    }


    HandleChange = event => {
        var { name, value } = event.target
        this.setState({
            [name]: value,
            user_nameerror: "",
            user_emailerror: "",
            phoneerror: "",
            locationerror: "",
            problemerror: "",
            messageerror: ""
        })
    }

    HandleSubmit = event => {
        event.preventDefault()
        emailjs
            .sendForm(
                'service_40ystbo',
                'template_uvrxgvj',
                event.target
            )
            .then((result) => {
                if (result) {
                    this.setState({
                        user_email: "",
                        user_name: "",
                        problem: "",
                        phone: "",
                        location: "",
                        message: ""
                    })
                    M.toast({ html: "Thank you for your message", displayLength: 4000 })
                }
                else {
                    M.toast({ html: "Something went wrong.Try again" })
                }
            })
            .catch((error) => {
                M.toast({ html: "Failed to send the message" })
            })
    }

    render() {
        return (
            <React.Fragment>
                <Navbar />
                <Whatsapp />
                <div className="row grey lighten-5" >
                    <div className="col l8 s12 m12 offset-l2">
                        <div className="row">
                            <br />
                            <div className="col l6 s12 m12">
                                <b > AFRICA OFFICES</b><br /> <br />
                                Location: <b>Kenya, Tanzania, Uganda, Rwanda, Burundi, Sudan, DR Congo & Africa </b><br /> <br />
                                Consultation Fee: <b>$20</b><br />
                                <br />
                                Whatsapp Number : <a href='tel:+243 990 627 777' className="green-text text-darken-2t"><b>+243 990 627 777</b></a><br /><br />
                                Email : <a href='mailto:bbakongwadoctors@gmail.com' className="green-text text-darken-2t"><b>bbakongwadoctors@gmail.com</b></a><br /><br /><br />
                                <b>MAIN OFFICE</b><br /><br />
                                DR Congo, <br /><br />
                                Lubumbashi,<br /><br />
                                Consultation Fee: <b>$20</b><br />
                                <br />
                                Whatsapp Number : <a href='tel:+243 990 627 777' className="green-text text-darken-2t"><b>+243 990 627 777</b></a><br /><br />
                                Email : <a href='mailto:bbakongwadoctors@gmail.com' className="green-text text-darken-2t"><b>bbakongwadoctors@gmail.com</b></a>
                            </div>
                            <div className="col l6 s12 m12">
                                <div className="card  row">
                                    <div className="col s10 m1o l10 push-l1 offset-s1 push-m1">
                                        <form className="contact-form" onSubmit={this.Validate}>
                                            <div>
                                                <label>Name</label>
                                                <input
                                                    type="text"
                                                    name="user_name"
                                                    value={this.state.user_name}
                                                    onChange={this.HandleChange}
                                                />
                                                <span className="helper-text red-text">{this.state.user_nameerror}</span>
                                            </div>
                                            <div>
                                                <label>Email</label>
                                                <input
                                                    type="email"
                                                    name="user_email"
                                                    value={this.state.user_email}
                                                    onChange={this.HandleChange}
                                                />
                                                <span className="helper-text red-text">{this.state.user_nameerror}</span>
                                            </div>
                                            <div>
                                                <label>Problem</label>
                                                <input
                                                    type="text"
                                                    name="problem"
                                                    value={this.state.problem}
                                                    onChange={this.HandleChange}
                                                />
                                                <span className="helper-text red-text">{this.state.user_nameerror}</span>
                                            </div>
                                            <div>
                                                <label>Phone Number</label>
                                                <input
                                                    type="tel"
                                                    name="phone"
                                                    value={this.state.phone}
                                                    onChange={this.HandleChange}
                                                />
                                                <span className="helper-text red-text">{this.state.user_nameerror}</span>
                                            </div>
                                            <div>
                                                <label>Location</label>
                                                <input
                                                    type="text"
                                                    name="location"
                                                    value={this.state.location}
                                                    onChange={this.HandleChange}
                                                />
                                                <span className="helper-text red-text">{this.state.user_nameerror}</span>
                                            </div>
                                            <div>
                                                <label>Message</label>
                                                <textarea

                                                    name="message"
                                                    value={this.state.message}
                                                    onChange={this.HandleChange}
                                                />
                                                <span className="helper-text red-text">{this.state.user_nameerror}</span>
                                            </div>
                                            <div className="card-action center">
                                                <input
                                                    type="submit"
                                                    value="Send"
                                                    className="btn-small green darken-2" />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        )
    }
}