import React, { useEffect, useState } from 'react';
import M from 'materialize-css';
import Loader from '../../components/loader';
import { servelURL } from '../../config';
import { Helmet } from 'react-helmet';
import DocumentViewer from '../../components/documentViewer';
import backgroundImage from '../../config/logo.jpg';
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'

const BlogRead = function (props) {
    const [loading, setLoading] = useState(false);
    const [dataObject, setDataObject] = useState('');
    const [showScrollButton, setShowScrollButton] = useState(false)

    useEffect(() => {
        if (props.location.state) {
            const { blog } = props.location.state;
            setDataObject(blog);
            document.title = `Blog | Bakongwa Doctors`;
        } else {
            mount();
        }

        async function mount() {
            try {
                const condition = JSON.stringify({
                    title: props.location.pathname.split('/').pop(),
                });
                const select = JSON.stringify({});
                const schema = 'blog';
                const joinForeignKeys = true;
                const parameters = `schema=${schema}&condition=${condition}&select=${select}&joinForeignKeys=${joinForeignKeys}`;
                let response = await fetch(`${servelURL}/read?${parameters}`, {
                    method: 'GET',
                    mode: 'cors',
                });
                if (response.ok) {
                    response = await response.json();
                    setDataObject(response.message);
                } else {
                    throw new Error('Failed to fetch data');
                }
            } catch (error) {
                new M.Toast({ html: error.message });
            }
            setLoading(false);
        }

        document.title = 'Blog | Bakongwa Doctors';
        M.AutoInit();

        const handleScroll = () => {
            const shouldShowButton = window.scrollY > 200;
            setShowScrollButton(shouldShowButton);
        };

        window.addEventListener('scroll', handleScroll);

        return function cleanup() {
            window.removeEventListener('scroll', handleScroll);
        };
        // eslint-disable-next-line
    }, [props.location.state, props.location.pathname]);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    React.useEffect(function () {
        // eslint-disable-next-line
    }, [])

    console.log(dataObject)

    return (
        <main>
            <Navbar />
            {loading ? <Loader /> : null}
            <div
                className='background-image'
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundSize: 'cover',
                    opacity: 0.2,
                    zIndex: -1,
                }}
            ></div>
            <Helmet>
                <meta property="og:title" content={`Bakongwa | ${dataObject?.title?.replace(/-/g, ' ')?.toUpperCase()}`} />
                <meta property="og:description" content={dataObject?.title?.replace(/-/g, ' ')?.toUpperCase()} />
                <meta property="og:image" content={dataObject?.pic || `bakongwadoctors.com/blog/blog/${dataObject?.pic}`} />
                <meta property="og:url" content={`https://bakongwadoctors.com/${props.location.pathname}`} />
                <meta property="og:type" content={dataObject?.title?.replace(/-/g, ' ')?.toUpperCase()} />
            </Helmet>
            <div className='row' style={{ marginTop: '2rem' }}>
                <img src={`${dataObject?.pic}`} alt='pic' width='100%' />
                <div className='col s10 m10 offset-s1 offset-m1'>
                    <h4 className='green-text center'>
                        <i>{dataObject.title?.replace(/-/g, ' ')?.toUpperCase()}</i>
                    </h4>
                    <div>
                        <DocumentViewer document={dataObject?.description} />
                    </div>
                </div>
            </div>
            {showScrollButton && (
                <button className='btn-floating btn-large green scroll-button' onClick={scrollToTop}>
                    Top
                </button>
            )}
            <Footer />
        </main>
    );
};

export default BlogRead;