import React, { Component } from 'react'
import Whatsapp from './components/Whatsaap'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import image90 from './components/Images/image90.jpg'

export default class Miscariage extends Component {

    componentDidMount() {
        document.title = 'Bakongwa Doctors'
    }


    render() {
        return (
            <main>
                <Navbar />
                <Whatsapp />
                <div className="grey lighten-5 row">
                    <br />
                    <div className="col s10 m10 l10 push-l1 push-s1 offset-m1">
                        <img src={image90} className="materialboxed col s12 m12" style={{ width: "100%" }} alt="miscariage" />
                        <span className="img-tittle green-text text-darken-2"><strong style={{ fontSize: "25px" }}> I was played not to keep money in my pocket.</strong></span>
                        <p>
                            I am doing a good job at a non-governmental organization that helps orphans, the elderly and all the underprivileged in Dodoma, our organization is called Diamanda Foundation, it is not very old, it is about ten years since we were launched, we rely on receiving aid from various organizations, the government, and all other institutions that are affected by our service that we provide at the office.

                            <br />

                            My salary is good enough to satisfy the family and run it well without any challenges. I have a wife and two sons who are still young and do not study. They earn at home with their mother. This organization was opened by me and my friend Remmy and when we hired several of our colleagues, it now has more than thirty employees who are all happy with our work and our pay as well.
                            <br />
                            For the time that I have worked, I would have known Dr.  BAKONGWA  earlier, I would not have been the one to cry again like others, or at least I would have known about his website <a href='https://bakongwacotors.com/contactus'>MURINGO</a> , I would not have regretted the time that I have wasted during my entire career at our office.
                            <br />
                            Our family situation is very normal, we have arranged a house that has neighbors who do not live there and sometimes they do not appear at all for more than two to three weeks without any information. At home, I let him make a spending plan for me and the rest of the money I keep inside.
                            <br />
                            The surprising thing is that every time I set aside money to provide the mother with children, the remaining amount is large, but I don't understand how it has been used or where it has gone - that is, there are always expenses and needs that were not in the goals and I did not expect at all in those days of our future.
                            <br />
                            The last time before I realized this was when I gave my wife spending money and after that the child suddenly got sick and I didn't even have any penny in my pocket and if you look at it only two days have passed since the day of receiving the salary, I almost lost my son for failing to take care of him money was a debt reliever for me.
                            <br />
                            I suspected that those who were our neighbors were doing something to bring to me or anything related to the loss and use of my money without any goals. I decided to bring a pastor to my house to do a joint service with us as the family was gone but still my money was not seen as it was spent randomly without arrangements .
                            <br />
                            Now my neighbors have moved but my situation is still the same, my money does not stay and its use is not visible, I decided to move home, maybe I will get a rescue but still nothing has gone right for me. Being in a state of failure and satisfaction with the suffering, I was given advice by Remmy to find a doctor in Bakonga his whatsapp numbers <a href='wa.me"//+243990627777'>+243990627777</a> and he will help me, I did that.
                            <br />
                            When the doctor got my information he did his expertise and then he found out that there are people like my friends who don't like achievers and they had tied me up so that I don't stay with money and yes every time there was an unfounded use he gave me my medicine and my family used it now I see economic development I even own a car to visit thank you very much doctor

                            <br /><br />
                        </p>
                        <strong>Connect with Us:<a href="tel:+243 990 627 777" className="green-text text-darken-2" > Phone : +243 990 627 777</a></strong> | <strong><a href="mailto:bbakongwadoctors@gmail.com" className="green-text text-darken-2" >Email :bbakongwadoctors@gmail.com </a></strong>
                    </div>
                </div>
                <Footer />
            </main>
        )
    }
}