import React, { useState } from 'react'
import M from 'materialize-css'
import { getUserInfo, servelURL } from '../../config/index'
import Loader from '../../components/loader'
import Button from '../../components/button'
import Input from '../../components/input'
import backgroundImage from '../../config/logo.jpg'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'

const BlogForm = function (props) {

    const [loading, setLoading] = React.useState(false)
    const [disabled, setDisabled] = React.useState(false)
    const [title, setTitle] = React.useState('')
    const [titleError, setTitleError] = React.useState('')
    const [description, setDescription] = React.useState('')
    const [edit, setEdit] = React.useState(false)
    const [id, setId] = React.useState('')
    const [image, setImage] = React.useState({})
    const [pic, setLink] = useState('')

    React.useEffect(function () {
        if (getUserInfo().role === 'admin') {
            if (props.location.state) {
                const { blog } = props.location.state
                document.title = 'Bakongwa Doctors | Edit Blog'
                setEdit(true)
                setTitle(blog.title?.replace(/-/g, ' ')?.toUpperCase())
                setDescription(blog.description)
                setId(blog._id)
                setImage(blog.image)
                setLink(blog.pic)
            }
            else document.title = 'Bakongwa Doctors | Create Blog'
        }
        else props.history.push('/blog/list')
        M.AutoInit()
        return function () { }
        // eslint-disable-next-line
    }, [])

    React.useEffect(function () {
        M.AutoInit()
    })

    function handleEditorChange(event, editor) {
        const newDescription = editor.getData();
        setDescription(newDescription);
    }

    const validate = function (event) {
        try {
            event.preventDefault()
            const errors = []

            if ((title !== undefined) && (title.trim() === '')) {
                setTitleError('Please fill this field')
                errors.push('')
                setDisabled(true)
            }

            if (errors.length === 0) {
                submit()
            }
            else setLoading(false)
        }
        catch (error) {
            setDisabled(true)
            if (error instanceof Error) {
                new M.Toast({ html: error.message })
            }
            else console.log(error)
        }
    }

    async function submit() {
        try {
            const formData = new FormData()
            formData.append('file', image)
            formData.append('body', JSON.stringify({
                folderName: 'blog'
            }))

            let response = await fetch(`${servelURL}/upload-file`, {
                method: 'POST',
                mode: 'cors',
                body: formData
            })
            response = await response.json()
            const newMessage = response.message.name
            if (response.success) {
                setLoading(true)
                let response = edit ? await fetch(`${servelURL}/update`, {
                    method: "PUT",
                    mode: 'cors',
                    headers: {
                        'Content-Type': "application/json"
                    },
                    body: JSON.stringify({
                        schema: 'blog',
                        newDocumentData: {
                            title: title.replace(/ /g, "-").toLowerCase(),
                            updated_by: getUserInfo('_id'),
                            description: description,
                            pic: pic
                        },
                        condition: { _id: id },
                        image: newMessage?.toUpperCase()
                    })
                })
                    : await fetch(`${servelURL}/create`, {
                        method: "POST",
                        mode: "cors",
                        headers: {
                            'Content-Type': "application/json"
                        },
                        body: JSON.stringify({
                            schema: "blog",
                            documentData: {
                                title: title.replace(/ /g, "-").toLowerCase(),
                                created_by: getUserInfo('_id'),
                                image: newMessage?.toUpperCase(),
                                description: description,
                                pic: pic
                            }
                        })
                    })
                response = await response.json()
                if (response.success) {
                    setLoading(false)
                    props.history.push({
                        pathname: "/blogs",
                        state: { blog: response.message }
                    })
                    new M.Toast({ html: edit ? "Blog updated successfully" : "Blog created successfully" })
                }
                else {
                    setLoading(false)
                    new M.Toast({ html: response.message })
                }
            }
            else {
                setLoading(false)
                let response = edit ? await fetch(`${servelURL}/update`, {
                    method: "PUT",
                    mode: 'cors',
                    headers: {
                        'Content-Type': "application/json"
                    },
                    body: JSON.stringify({
                        schema: 'blog',
                        newDocumentData: {
                            title: title.replace(/ /g, "-"),
                            updated_by: getUserInfo('_id'),
                            description: description,
                            pic: pic
                        },
                        condition: { _id: id },
                        image: newMessage?.toUpperCase()
                    })
                })
                    : await fetch(`${servelURL}/create`, {
                        method: "POST",
                        mode: "cors",
                        headers: {
                            'Content-Type': "application/json"
                        },
                        body: JSON.stringify({
                            schema: "blog",
                            documentData: {
                                title: title.replace(/ /g, "-"),
                                created_by: getUserInfo('_id'),
                                image: newMessage?.toUpperCase(),
                                description: description,
                                pic: pic
                            }
                        })
                    })
                response = await response.json()
                if (response.success) {
                    setLoading(false)
                    props.history.push({
                        pathname: "/blog/list",
                        state: { category: response.message }
                    })
                    new M.Toast({ html: edit ? "Blog updated successfully" : "Blog Created successfully" })
                }
                else {
                    setLoading(false)
                    new M.Toast({ html: response.message })
                }
            }
        }
        catch (error) {
            setDisabled(true)
            if (error instanceof Error) {
                new M.Toast({ html: error.message })
            }
            else console.log(error)
        }
    }

    return (
        <main>
            <Navbar />
            {
                loading ? <Loader /> : null
            }
            <div
                className='background-image'
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundSize: 'cover',
                    opacity: 0.2,
                    zIndex: -1,
                }}
            >
            </div>
            <div className='row' style={{ marginTop: '2rem' }}>
                <div className='col s10 offset-s1 m10 offset-m1'>
                    <form className='container' onSubmit={validate}>
                        <div className='card hoverable'>
                            <div className='card-content'>
                                <span className='card-title center'>{edit ? "Edit Blog" : "Create Blog"}</span>
                                <div className='row'>
                                    <div className='col s12 m6'>
                                        <Input
                                            name='title'
                                            value={title}
                                            type='text'
                                            label='Blog Title'
                                            onChange={(event) => setTitle(event.target.value, setDisabled(false), setTitleError(''))}
                                        />
                                        <span className='helper-text red-text'>{titleError ? titleError : ""}</span>
                                    </div>
                                    <div className='col s12 m6 form-group'>
                                        <Input
                                            name='pic'
                                            value={pic}
                                            type='text'
                                            label='Blog Image Link'
                                            onChange={(event) => setLink(event.target.value, setDisabled(false))}
                                        />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col s12'>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data={description}
                                            onChange={handleEditorChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='card-action center'>
                                <Button
                                    className='btn-small green'
                                    disabled={disabled ? true : false}
                                    type={edit ? "Update" : "Post"}
                                    icon='send'
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <Footer />
        </main>
    )
}

export default React.memo(BlogForm)