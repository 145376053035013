import React from 'react';

export default function Loader() {
  const loaderStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    zIndex: 9999,
  };

  const spinnerStyle = {
    position: 'relative',
    width: '64px',
    height: '64px',
  };

  const textStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'green',
    fontSize: '48px',
    fontWeight: 'bold',
    animation: 'spin 2s linear infinite',
  };

  return (
    <div style={loaderStyle}>
      <div className="preloader-wrapper big active" style={spinnerStyle}>
        <div className="spinner-layer spinner-red">
          <div className="circle-clipper left">
            <div className="circle"></div>
          </div>
          <div className="gap-patch">
            <div className="circle"></div>
          </div>
          <div className="circle-clipper right">
            <div className="circle"></div>
          </div>
        </div>
      </div>
      <div style={textStyle}>B</div>
    </div>
  );
}
