import React, { Component } from 'react'
import Whatsapp from './components/Whatsaap'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import image89 from './components/Images/imag89.jpg'

export default class Miscariage extends Component {

    componentDidMount() {
        document.title = 'Bakongwa Doctors'
    }


    render() {
        return (
            <main>
                <Navbar />
                <Whatsapp />
                <div className="grey lighten-5 row">
                    <br />
                    <div className="col s10 m10 l10 push-l1 push-s1 offset-m1">
                        <img src={image89} className="materialboxed col s12 m12" style={{ width: "100%" }} alt="miscariage" />
                        <span className="img-tittle green-text text-darken-2"><strong style={{ fontSize: "25px" }}>Niliteseka kwa kutotimiza haja za mke wangu wa ndoa ..</strong></span>
                        <p>
                            "My name is Witness, those who envy me call me Wit, I was lucky enough to marry the son of our boss at the company I work for in Meru. A lavish wedding was held in which our colleagues attended and gave many gifts.

                            I stayed married for nine years and gave birth to my husband four daughters, our desire to have a son is what made us not know the methods of birth control and raising the children like a ladder, although this was not a problem because the family I was married to was very money and made each of my daughters be given a working sister to raise her alone.


                            <br />
                            Every time we tried we were unsuccessful, we ended up having a girl child and sometimes several pregnancies were miscarried before giving birth. We decided to get the advice of specialist doctors in Meru and they insisted that if we wanted to have a boy child we had to have sex on the first day of my risk, this time we had a girl again we had five daughters.

                            <br />

                            Shame gripped me on the part of my husband, pity increased and we began to reduce our love little by little for not being able to give birth to a single son.

                            <br />

                            One of the working ladies who was there at home urged me to try alternative medicine and urged me to find a doctor  BAKONGWA . This time I did not hesitate to do anything because we all needed a son when I informed my husband he did not object and he agreed with this idea.

                            <br />

                            We looked for a doctor on his whatsapp number <a href='wa.me://+243990627777'>+243990627777</a>  and asked him to solve our problem, he gave us instructions to do and remedies to use for two days, when we finished the medicine, we met again with my husband. male
                            <br />
                            Thank you very much doctor for your services, our dreams of having a son have been completed quickly and my husband's love for me has doubled from the beginning"
                        </p>
                        <strong>Connect with Us:<a href="tel:+243 990 627 777" className="green-text text-darken-2" > Phone : +243 990 627 777</a></strong> | <strong><a href="mailto:bbakongwadoctors@gmail.com" className="green-text text-darken-2" >Email :bbakongwadoctors@gmail.com </a></strong>
                    </div>
                </div>
                <Footer />
            </main>
        )
    }
}