import React, { Component } from 'react'
import Navbar from './components/Navbar'
import Whatsapp from './components/Whatsaap'
import Marquee from "react-fast-marquee"
import image16 from './components/Images/image16.jpg'
import image17 from './components/Images/image17.jpg'
import image18 from './components/Images/image18.jpg'
import image19 from './components/Images/image19.jpg'
import image20 from './components/Images/image20.jpg'
import image21 from './components/Images/image21.jpg'
import image22 from './components/Images/image22.jpg'
import image23 from './components/Images/image23.jpg'
import image24 from './components/Images/image24.jpg'
import image25 from './components/Images/image25.jpg'
import image26 from './components/Images/image26.jpg'
import image27 from './components/Images/image27.jpg'
import image28 from './components/Images/image28.jpg'
import image29 from './components/Images/image29.jpg'
import image30 from './components/Images/image30.jpg'
import image31 from './components/Images/image31.jpg'
import image32 from './components/Images/image32.jpg'
import { Link } from 'react-router-dom'
import Footer from './components/Footer'

export default class TraditionalHerbs extends Component {

    componentDidMount() {
        document.title = 'Bakongwa Doctors'
    }

    
    render() {
        return (
            <React.Fragment>
                <Navbar />
                <Whatsapp />
                <div className="grey lighten-5 container ">
                    <Marquee className="green-text text-darken-2 center" style={{ fontSize: '25px' }} speed='80' direction="right" ><b>Bakongwa Doctors Traditional Herbs</b></Marquee>
                    <div className="row">
                        <div className="col s12 m12 l6">
                            <h4 className="green-text text-darken-2 center"><b></b>HERBAL MEDICINE TO CURE</h4>
                            <p> Bakongwa Doctors have decades of experience on strong herbal medicines to cure the following illnesses;<br /><br />
                                Broken bones, lost memory, chest pains, heartache, headache, high fever, piles, leprosy, teeth ache, neck pains, back pains, poisons, high blood pressure, sugar diabetes, diarrhea, cancer, lung infections, strong cough, meningitis, allergies, pimples, lost appetite, eye ache, small pox, ringworm, leukemia, snake bites, birth pains, stomach pains, gonorrhea, syphilis, dysentery, shaking palsy, paralysis, epilepsy, short breathe, skin infections, cholera and much more.</p>
                        </div><br /><br />
                        <div className="col s12 m12 l6">
                            <img src={image16} alt="medicine to cure" className="materialboxed" style={{ width: "100%" }} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s12 m12 l6">
                            <h4 className="green-text text-darken-2 center"><b></b>IMPROVE YOUR SEX</h4>
                            <p>You wish you could have that beautiful lady as your partner.You spend a lot of time chasing her and all in all you get the queen of your heart. Definitely, love will not be complete without quality sexual intercourse. Because of your short 'gun', the queen is not satisfied whenever you engage in sex. More so when she coughs the moment you are inside, the pen gets out immediately. This is a quite embarrassing scene.<br /><br />
                                It is evident that all ladies enjoy a pen that scratches deep deep inside. If you are a guy with a small 'gun' and you want your sexual life restored for good, consult Bakongwa Doctors. The doctor will treat you using three doses of herbal drugs and all will be well after four days.</p>
                        </div><br /><br />
                        <div className="col s12 m12 l6">
                            <img src={image17} alt="medicine to cure" className="container materialboxed" style={{ width: "100%" }} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s12 m12 l6">
                            <h4 className="green-text text-darken-2 center"><b></b>HIPS & BUM ENLARGEMENT</h4>
                            <p>Watch me rolling the goodies; I want all men to always turn around their heads whenever I pass by... This is a dream that all beautiful ladies aspire to have. Actually it is the Hips and Bum that brings out the beauty. Ladies, do not let your man divorce you because of your tiny bum and hips that cannot even match the beauty. I will make your hips and bum attractive within 7 days. Get an appointment today.</p>
                        </div><br />
                        <div className="col s12 m12 l6">
                            <img src={image18} alt="medicine to cure" className="container materialboxed" style={{ width: "100%" }} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s12 m12 l6">
                            <h4 className="green-text text-darken-2 center"><b></b>GONORRHEA (KISONONO)</h4>
                            <p>Gonorrhea is an infection caused by a sexually transmitted bacterium that infects both males and females. Gonorrhea most often affects the urethra, rectum or throat. In females, gonorrhea can also infect the cervix. Gonorrhea is most commonly spread during vaginal, oral or anal sex. But babies of infected mothers can be infected during childbirth. In babies, gonorrhea most commonly affects the eyes.<br /><br />
                                With my vast knowledge to treating STIs, I will treat you within 14 days using herbal medicine. Try the Doctor today.</p>
                        </div><br />
                        <div className="col s12 m12 l6">
                            <img src={image19} alt="medicine to cure" className="container materialboxed" style={{ width: "100%" }} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s12 m12 l6">
                            <h4 className="green-text text-darken-2 center"><b></b>PENIS ENLARGEMENT</h4>
                            <p>You wish you could have that beautiful lady as your partner.You spend a lot of time chasing her and all in all you get the queen of your heart. Definitely, love will not be complete without quality sexual intercourse. Because of your short 'gun', the queen is not satisfied whenever you engage in sex. More so when she coughs the moment you are inside, the pen gets out immediately. This is a quite embarrassing scene.<br /><br />
                                It is evident that all ladies enjoy a pen that scratches deep deep inside. If you are a guy with a small 'gun' and you want your sexual life restored for good, consult Bakongwa Doctors. The doctor will treat you using three doses of herbal drugs and all will be well after four days.</p>
                        </div><br />
                        <div className="col s12 m12 l6">
                            <img src={image20} alt="medicine to cure" className="container materialboxed" style={{ width: "100%" }} />
                        </div>
                    </div>
                    <div className="center">
                        <a href="tel:+243 990 627 777" className="btn green darken-2"><i className="material-icons left">phone</i>CALL DOCTOR Bakongwa NOW</a>
                    </div><br />
                    <div className="row">
                        <div className="col s10 m10 l10 push-l1 push-s1 push-m1" >
                            <div className="card row">
                                <div className="card-image col s12 m12 l4">
                                    <img src={image21} className="container materialboxed" alt="financialprobles" />
                                    <span className="card-title white-text">Love Problems</span>
                                </div>
                                <div className="card-image col s12 m12 l4">
                                    <img src={image22} className="container materialboxed" alt="financialprobles" />
                                    <span className="card-title white-text">Financial Problems</span>
                                </div>
                                <div className="card-image col s12 m12 l4">
                                    <img src={image23} className="container materialboxed" alt="financialprobles" />
                                    <span className="card-title white-text">Pass Job Interviews</span>
                                </div>
                            </div>
                            <div className="card row">
                                <div className="card-image col s12 m12 l4">
                                    <img src={image26} className="container materialboxed" alt="financialprobles" />
                                    <span className="card-title white-text">Education</span>
                                </div>
                                <div className="card-image col s12 m12 l4">
                                    <img src={image25} className="container materialboxed" alt="financialprobles" />
                                    <span className="card-title white-text">Marriage Problems</span>
                                </div>
                                <div className="card-image col s12 m12 l4">
                                    <img src={image24} className="container materialboxed" alt="financialprobles" />
                                    <span className="card-title white-text">Family Curses</span>
                                </div>
                            </div>
                            <div className="card row">
                                <div className="card-image col s12 m12 l4">
                                    <img src={image27} className="container materialboxed" alt="financialprobles" />
                                    <span className="card-title white-text">Enemies</span>
                                </div>
                                <div className="card-image col s12 m12 l4">
                                    <img src={image28} className="container materialboxed" alt="financialprobles" />
                                    <span className="card-title white-text">Penis Elargment</span>
                                </div>
                                <div className="card-image col s12 m12 l4">
                                    <img src={image29} className="container materialboxed" alt="financialprobles" />
                                    <span className="card-title white-text">Work Related Problems</span>
                                </div>
                            </div>
                            <div className="card row">
                                <div className="card-image col s12 m12 l4">
                                    <img src={image30} className="container materialboxed" alt="financialprobles" />
                                    <span className="card-title white-text">Barrenness</span>
                                </div>
                                <div className="card-image col s12 m12 l4">
                                    <img src={image31} className="container materialboxed" alt="financialprobles" />
                                    <span className="card-title white-text">Witchft Matters</span>
                                </div>
                                <div className="card-image col s12 m12 l4">
                                    <img src={image32} className="container materialboxed" alt="financialprobles" />
                                    <span className="card-title white-text">Nightmares</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="center">
                        <Link to='/ContactUs' className="btn-small green darken-2">Request Help Now</Link>
                    </div><br />
                    <h5 className="green-text text-darken-2 center" style={{ 'fontSize': "40px" }} ><b>WORDS FROM OUR LAST PATIENTS</b></h5>
                    <div className="row">
                        <div className="col s10 m10 l10 offset-l1 push-s1 push-m1">
                            <div className="card-panel row">
                                <div className="col s12 m12 l4">
                                    <span>"I highly recommend the Bakongwa doctors to you for whatever problems you are experiencing whether is love, protection, money or psychic powers. Bakongwa Doctors are the real deal. When I had over a thousand and one problems, Bakongwa Doctors solved then in a span of Minutes. Thank you so Much Bakongwa Doctors!"</span><br />
                                    <i className="green-text text-darken-2">Wollen - Tanzania</i>
                                </div>
                                <div className="col s12 m12 l4">
                                    <span>"I have had amazing things happen during the first week of my casting. Jason has done a 360 and is now talking with me and wanting to spend time with me again. I can't believe after all that has happened he actually turned around and started talking and wanting to be with me. It's AMAZING!  You are like a god sends an angel in my life. What can I say? Veronica and he just split up and he wants now to be with me. Thank you very much for your help Bakongwa Doctor."</span><br />
                                    <i className="green-text text-darken-2">Celine - Uganda</i>
                                </div>
                                <div className="col s12 m12 l4">
                                    <span>"""My neck doesn't hurt any more" I am forever grateful for your help Doctor Bakongwa! Kindness and patience is with me. The pain I was feeling in my neck completely disappeared like you had promised me and I don't feel all that negative energy around me. You are the best Doctor Bakongwa! Thank you thank you, thank you, a thousand times."</span><br />
                                    <i className="green-text text-darken-2">Vidya - Tanzania</i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        )
    }
}