import React, { Component } from 'react'
import Whatsapp from './components/Whatsaap'
import Navbar from './components/Navbar'
import image85 from './components/Images/image85.jpg'
import Footer from './components/Footer'

export default class EnemiesSolution extends Component {

     componentDidMount() {
        document.title = 'Bakongwa Doctors'
    }

    
    render() {
        return (
            <React.Fragment>
                <Navbar /><br />
                <Whatsapp />
                <div className="row grey lighten-5 row">
                    <div className="col s10 m10 l10 push-l1 push-s1 offset-m1">
                        <img src={image85} alt="image73" className="materialboxed col s12 m12" />
                        <span className="img-tittle green-text text-darken-2"><strong style={{ fontSize: "25px" }}>I WAS RAPED AND INFECTED WITH HIV BY MY WICKED UNCLE WHEN I WAS A LITTLE GIRL (EMOTIONAL)</strong></span>
                        <p>
                            My name is Laura. I was left orphaned at the age of 16 and I had to move in with my uncle so that I could continue going to school. However, when I moved in with him, life became a living hell because he would rape every night when his wife was dead asleep. Though I ran away from that home, I contracted HIV from him and when I found out, I was devastated because I knew my life would be miserable with the virus.
                            I felt as if my life had come to and end. I had so much rage against that uncle of mine who had knowingly infected me with HIV. I wanted to kill him with my bare hands for all the hurt he had caused me. I started taking ARVs soon enough so that I could at least feel better. When I finished school, and got a job, I decided to make it my mission to revenge my uncle.
                            I started researching on how I could make him pay and it is during my search that I stumbled upon Doctor Bakongwa’s website where he had detailed that he could use his spell-casting power to teach any wrong doers a lesson. I called the doctor on +243 990 627 777. and after I told him my situation, he asked me to visit his workplace.
                            The best part was that he could heal me the HIV. He used a combination of spells and traditional herbs to heal me. In less than 24 hours, the virus had disappeared from my body. I was so happy. Secondly, he also cast a spell which compelled my uncle to confess that he raped me to the entire family. He also surrendered himself to the police and he is serving his life-sentence.
                            I am just happy I got my life back all thanks to Doctor Bakongwa. I advise anyone with a similar problem to visit Dr. Bakongwa. Just like a medical doctor, Dr. Bakongwa does a diagnosis on his clients by asking a few questions for self-introspection in the area of one’s life such as challenges in financial and physical aspects, among others.
                            Dr. Bakongwa says his spell casting powers work within 24 hours, and usually within the same day they are released. He handles general problems ranging from winning court cases, winning the lottery, protection of family and property, as well as accurately foretelling one’s future.
                            Many others who have been assisted by Dr. Bakongwa say they have received powerful healing and are now more connected to their fellow lonely hearts.
                            Contact herbalist Dr. Bakongwa. He heals pressure, diabetes, ulcers, gonorrhea, syphilis, TB, manhood weakness among other things.
                            The doctor also solves life’s challenges such as love issues, family problems, hardships in business, increases your luck, that is, winning lottery games and court cases, promotions at work and clears away devilish spirits and dreams.</p>
                        <strong>Connect with Us:<a href="tel:+243 990 627 777" className="green-text text-darken-2" > Phone : +243 990 627 777</a></strong> | <strong><a href="mailto:bbakongwadoctors@gmail.com" className="green-text text-darken-2" >Email :bbakongwadoctors@gmail.com </a></strong>
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        )
    }
}