import { Link } from 'react-router-dom'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'

export default function PageNotFound() {
    return (
        <main>
            <Navbar />
            <div className='row' style={{ marginTop: "2rem" }}>
                <div className='col s12 m10 offset-m1'>
                    <Link to={'/'} className='btn-small green'>Go back home</Link>
                </div>
            </div>
            <div className='page'>
                <img src='/404.webp' alt='pics is here' width='100%' />
            </div>
            <Footer />
        </main>
    )
}