import React, { Component } from 'react'
import image3 from './Images/image3.jpg'

export default class Part extends Component {

     componentDidMount() {
        document.title = 'Bakongwa Doctors'
    }

    
    render() {
        return (
            <React.Fragment>
                <div className="row spell">
                    <div className="col l6 m12 s12">
                        <h4>TRADITIONAL HERBS</h4>
                        <p>
                            Bakongwa Doctors have decades of experience on strong herbal medicines to cure the following illnesses;
                            Broken bones, lost memory, chest pains, heartache, headache, high fever, piles, leprosy, teeth ache, neck pains, back pains, poisons, high blood pressure, sugar diabetes, diarrhea, cancer, lung infections, strong cough, meningitis, allergies, pimples, lost appetite, eye ache, small pox, ringworm, leukemia, snake bites, birth pains, stomach pains, gonorrhea, syphilis, dysentery, shaking palsy, paralysis, epilepsy, short breathe, skin infections, cholera and much more.
                        </p>
                    </div>
                    <div className="col l6 m12 s12">
                        <img src={image3} alt="Bakongwa Doctor services" className="container materialboxed" style={{ width: "100%" }}  />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}