import React, { Component } from 'react'
import image72 from './components/Images/jela.jpg'
import Whatsapp from './components/Whatsaap'
import Navbar from './components/Navbar'
import Footer from './components/Footer'

export default class Drug extends Component {

    componentDidMount() {
        document.title = 'Bakongwa Doctors'
    }


    render() {
        return (
            <React.Fragment>
                <Navbar /><br />
                <Whatsapp />
                <div className="row grey lighten-5 row">
                    <div className="col s10 m10 l10 push-l1 push-s1 offset-m1">
                        <img src={image72} alt="image72" className="materialboxed col s12 m12" />
                        <span className="img-tittle green-text text-darken-2"><strong style={{ fontSize: "25px" }}>I HAVE BEEN CHARGED WITH THE RAPE OF MY SISTER-IN-LAW</strong></span>
                        <p>
                            My name is George from Kakamega, and in my life, I have learned that someone you trust so much can do something very harmful to you, although there are also good people.

                            I had a girlfriend with whom we loved each other so much that we started making plans to get married in the future. However, it all came to a terrible end for me, something I can never forget.

                            This happened after my girlfriend accused me of raping her younger sister, who had come to visit us, and it was her usual routine to visit us.

                            I was arrested and taken to court, facing charges of rape. My friends and family came to bail me out, and I was able to return home. Since then, my life was filled with a lot of stress because the person I trusted did something so bizarre. I reached a point where I regretted my decision to be with her in life.

                            My friend Joshua came to visit me at home to console me for the troubles I was going through. I am grateful for his visit because it brought good news that could lift me out of those troubles.

                            He gave me the number of Bakongwa Doctors, telling me they had helped many people win their cases even when there seemed to be a very great difficulty.

                            The number he gave me is +243 990 627 777, which I still have on my phone to this day, and I have no intention of deleting it due to the help this person provided me.

                            I managed to contact Bakongwa Doctors and asked for their help to win the case that deprived me of sleep and brought shame to me in the community because others perceived me as a person with bad intentions.

                            After Bakongwa Doctors treated me, I continued with the case until the day of judgment, where many people were eager to know my fate.

                            I am grateful that the judge declared me innocent, and all charges were dropped. I cannot forget that day; I cried tears of joy for that victory, which removed shame and the bad image that had built up at home. Thank you so much, Bakongwa Doctors.

                            Remember, Bakongwa Doctors have the ability to bring luck into someone's life, win lottery games, protect you from enemies, help you win conflicts like land disputes, have a romantic allure, and increase sales in business.

                            They also treat diseases such as syphilis, gonorrhea, tuberculosis, hypertension, epilepsy, erectile dysfunction, swollen legs, and they can help women conceive and men to impregnate, among other things.
                        </p>
                        <strong>Connect with Us:<a href="tel:+243 990 627 777" className="green-text text-darken-2" > Phone : +243 990 627 777</a></strong> | <strong><a href="mailto:bbakongwadoctors@gmail.com" className="green-text text-darken-2" >Email :bbakongwadoctors@gmail.com </a></strong>
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        )
    }
}