import React, { Component } from 'react'
import Whatsapp from './components/Whatsaap'
import Navbar from './components/Navbar'
import image78 from './components/Images/image788.jpg'
import Footer from './components/Footer'

export default class MarriageSolution extends Component {

    componentDidMount() {
        document.title = 'Bakongwa Doctors'
    }


    render() {
        return (
            <React.Fragment>
                <Navbar /><br />
                <Whatsapp />
                <div className="row grey lighten-5 row">
                    <div className="col s10 m10 l10 push-l1 push-s1 offset-m1">
                        <img src={image78} alt="image73" className="materialboxed col s12 m12" />
                        <span className="img-tittle green-text text-darken-2"><strong style={{ fontSize: "25px" }}>This medicine has enabled me to enjoy eatnig the fruits well now!.</strong></span>
                        <p>
                            The truth is that a man's true honor from his wife comes when he fulfills his responsibilities well in taking care of the family, and most importantly, during dinner, a husband should ensure that the wife eats and is satisfied.

                            As grown-ups, we understand each other well in this most sensitive matter; it's our battlefield to boast, showcase our abilities, and prove our manhood.

                            In my marriage, there was a period where I faced a health issue that prevented me from satisfying my wife to the point where she began to disrespect me, openly declaring that she would go outside of our marriage. I have lived in marriage for many years, seen a lot, gone through ups and downs, laughed and cried, and now I am with my wife.

                            I can say that if it weren't for Bakongwa Doctors, my marriage would have already broken. I thank my friend who gave me the contact, I call it the miracle number for what I experienced.

                            I talked to Bakongwa Doctors about my challenge of being unable to please my wife. I remember the Saturday I spoke to them; they assured me that the problem would become history in my life from that day onward.

                            Bakongwa Doctors sent me medicine, and within a short time, I could see changes. My wife was so delighted to the point of praising me for how I could give her what she deserved as a legitimate wife.

                            I remember the moment my wife started shedding tears of joy for the pleasure she found in our marriage. She decided to apologize for her mockery during the times I couldn't satisfy her.

                            She told me she was filled with anger when she didn't need my services, and I wasn't capable. It caused her much pain until she reached the point of accepting me again.

                            I realized the mistake was mine for not seeking a solution earlier. I forgave her, and now we live well in our marriage. I truly never tire of thanking Bakongwa Doctors.

                            I urge my fellow men not to stay silent about the challenge of erectile dysfunction; reliable medicine exists with Bakongwa Doctors.

                            Remember, Bakongwa Doctors have the ability to bring luck into someone's life, win lottery games, protect you from enemies, help you win conflicts like land disputes, have a romantic allure, and increase sales in business.






                            .</p>
                        <strong>Connect with Us:<a href="tel:+243 990 627 777" className="green-text text-darken-2" > Phone : +243 990 627 777</a></strong> | <strong><a href="mailto:bbakongwadoctors@gmail.com" className="green-text text-darken-2" >Email :bbakongwadoctors@gmail.com </a></strong>
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        )
    }
}