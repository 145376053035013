import React, { Component } from 'react'
import Navbar from './components/Navbar'
import Whatsapp from './components/Whatsaap'
import image6 from './components/Images/image6.jpg'
import emailjs from 'emailjs-com'
import Footer from './components/Footer'
import image33 from './components/Images/image33.jpg'
import image34 from './components/Images/image34.jpg'
import image35 from './components/Images/image35.jpg'
import image36 from './components/Images/image36.jpg'
import image37 from './components/Images/image37.jpg'
import M from 'materialize-css'
import { init } from 'emailjs-com';
init("MD7C4ZGC3jNxr0dg7");

export default class Mwinganews extends Component {

    componentDidMount() {
        document.title = 'Bakongwa Doctors'
    }

    

    state = {
        user_name: "",
        user_email: "",
        user_phone: "",
        user_date: "",
        message: "",
        user_nameerror: "",
        user_emailerror: "",
        user_phoneerror: "",
        user_dateerror: "",
        messageerror: "",
        disbaled: false
    }

    HandleChange = event => {
        var { name, value } = event.target
        this.setState({
            [name]: value,
            user_phoneerror: "",
            user_emailerror: "",
            user_dateerror: "",
            messageerror: "",
            user_nameerror: "",
            disabled: false,
        })
    }

    Validate = event => {
        var errors = []
        event.preventDefault()
        this.setState({
            disabled: true
        })
        if (!this.state.user_name) {
            errors.push("")
            this.setState({
                user_nameerror: "this field is required"
            })
        }
        if (!this.state.user_email) {
            errors.push("")
            this.setState({
                user_nameerror: "this field is required"
            })
        }
        if (!this.state.user_date) {
            errors.push("")
            this.setState({
                user_dateerror: "this field is required"
            })
        }
        else if (!this.state.user_date < new Date().toISOString()) {
            errors.push('')
            this.setState({
                user_dateerror: "Date choosen not valid"
            })
        }
        if (!this.state.user_phone) {
            errors.push("")
            this.setState({
                user_phoneerror: "this field is required"
            })
        }
        if (!this.state.message) {
            errors.push("")
            this.setState({
                messageerror: "this field is required"
            })
        }
        if (errors.length === 0) {
            this.HandleSubmit(event)
        }
    }

    HandleSubmit = event => {
        event.preventDefault()
        emailjs
            .sendForm(
                'service_40ystbo',
                'template_hnv9ehg',
                event.target
            )
            .then((result) => {
                console.log(result)
                if (result) {
                    this.setState({
                        user_name: "",
                        message: "",
                        user_phone: "",
                        user_email: ""
                    })
                    M.toast({ html: "Thank you for your message." })
                }
                else {
                    M.toast({ html: "Failed. Please try again later." })
                }
            })
            .catch((error) => {
                M.toast({ html: "Failed . Try again later." })
            })
    }

    render() {
        return (
            <React.Fragment>
                <Navbar />
                <Whatsapp />
                <div className="grey lighten-5 container ">
                    <div className="row card spell ">
                        <div className="col s10 m10 l10 push-l1 push-s1 offset-m1">
                            <div className="row">
                                <div className="col s12 m12 l6">
                                    <div className="center">
                                        <img src={image6} alt="Dr.Bakongwa" className='materialboxed' style={{ width: "100%" }} />
                                    </div>
                                    <div className="card-content">
                                        <p>   Hi, my name is Bakongwa Doctor. I am gifted in treating and healing using African Traditional Herbs in the world. With over decade of experience, I treat and heal a wide range of illness including epilepsy, broken bones, lost memory, chest pains, heartache, headache, high fever, piles, leprosy, teeth ache, neck pains, back pains, poisons, high blood pressure, sugar diabetes, diarrhoea, cancer, lung infections, strong cough, meningitis, allergies, pimples, lost appetite, eye ache, small pox, ringworm, leukemia, snake bites, birth pains, stomach pains, gonorrhea, syphilis, dysentery, shaking palsy, paralysis, short breathe, skin infections, cholera, miscarriages and many more.
                                            I am also gifted in casting powerful and genuine spells in the world. These spells include: money spells, love spells, lost love spells, success spells, career spells among others. Our casting spell is within 3 days. Get your spell today and within 3 days your problem is solved.</p>
                                    </div>
                                </div><br /><br />
                                <div className="col s12 m12 l6 card ">
                                    <h5 className="center" style={{ fontSize: "30px" }}>Make an Appointment</h5>
                                    <h6 className="green-text text-darken-2 text-darken-2 center">(Consultation Fee is $20)</h6>
                                    <form className="contact-form" onSubmit={this.Validate} >
                                        <div className="row">
                                            <div className=" col l6 m12 s12">
                                                <label>Name</label>
                                                <input
                                                    type="text"
                                                    name="user_name"
                                                    value={this.state.user_name}
                                                    onChange={this.HandleChange}
                                                />
                                                <span className="helper-text red-text">{this.state.user_nameerror}</span>
                                            </div>
                                            <div className="col s12 m12 l6">
                                                <label>Email</label>
                                                <input
                                                    type="email"
                                                    name="user_email"
                                                    value={this.state.user_email}
                                                    onChange={this.HandleChange}
                                                />
                                                <span className="helper-text red-text">{this.state.user_nameerror}</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col s12 m1 l6">
                                                <label>Date</label>
                                                <input
                                                    type="date"
                                                    name="user_date"
                                                    value={this.state.user_date}
                                                    onChange={this.HandleChange}
                                                />
                                                <span className="helper-text red-text">{this.state.user_nameerror}</span>
                                            </div>
                                            <div className="col s12 m12 l6">
                                                <label>Phone</label>
                                                <input
                                                    type="tel"
                                                    name="user_phone"
                                                    value={this.state.user_phone}
                                                    onChange={this.HandleChange}
                                                />
                                                <span className="helper-text red-text">{this.state.user_nameerror}</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col s12 m12 l12">
                                                <label>Message</label>
                                                <textarea
                                                    value={this.state.message}
                                                    name="message"
                                                    onChange={this.HandleChange}
                                                />
                                                <span className="helper-text red-text">{this.state.user_nameerror}</span>
                                            </div>
                                        </div>
                                        <div className="card-cation center">
                                            <input type="submit"
                                                value="Send"
                                                className="btn-small green darken-2"
                                                disabled={this.state.disbaled}
                                            />
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s12 m12 l6">
                                    <h3 className="green-text text-darken-2 center">Barreness</h3>
                                    <p>Getting pregnant and carrying a pregnancy to term are actually very complicated processes. Many things can go wrong during these processes to lead to infertility. For this reason, women at child bearing age get tortured psychologically. Since it is joyous once a woman realizes that she has conceived, a woman who is having difficulty getting pregnant or carrying a pregnancy to term can talk to Dr. Bakongwa about possible treatments. Book an appointment today.</p>
                                </div><br /><br /><br />
                                <div className="col s12 m12 l6">
                                    <img src={image33} alt="Barrenness" className="materialboxed container" style={{ width: "100%" }} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s12 m12 l6">
                                    <h3 className="green-text text-darken-2 center">Divine Future</h3>
                                    <p>It seems humans have for a very long time been troubled by the opacity of the future. They'd like to have a better sense of what lies ahead, and they've come up with some ingenious ways of trying to get at that information. Divination is the art or practice that seeks to foresee or foretell future events, or to discover hidden knowledge. Divination usually involves the interpretation of omens or, if you're really lucky, the assistance of supernatural powers. Let us know if any of these work for you once you get a divination spell from Dr. Bakongwa.</p>
                                </div><br />
                                <div className="col s12 m12 l6">
                                    <img src={image34} alt="Barrenness" className="materialboxed container" style={{ width: "100%" }} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s12 m12 l6">
                                    <h3 className="green-text text-darken-2 center">Win Court Cases</h3>
                                    <p>Court Case spells are used to solve any type of court case or legal matter problems. People are accused of things they never do and others did but need a retreat. Come to me and get a court spell you can use to win any kind of case whether you are guilty or not. If you are suffering and tired and the case or any legal matter that you are going through is not ending, then you may contact me for a very strong court case spell and you overcome it.</p>
                                </div><br /><br />
                                <div className="col s12 m12 l6">
                                    <img src={image35} alt="Barrenness" className="materialboxed container" style={{ width: "100%" }} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s12 m12 l6">
                                    <h3 className="green-text text-darken-2 center">Magic Rings</h3>
                                    <p>Magic rings are powerful rings that have been cast with a spell. A magic ring contains the spiritual energy of the spell that has been cast on it. A magic ring for love is a love spell that is attached to a ring. A magic ring for money is a money spell attached to a ring. A magic ring for protection is a protection spell attached to a ring. Shield your aura from any spiritual harm with magic rings. You can also use magic rings for healing spiritual & physical ailments to eliminate the root cause of sickness & disturbance. Magic rings have different spiritual properties depending on the type of spell cast on the globe .Contact Dr. Bakongwa now to get your magic ring.</p>
                                </div><br />
                                <div className="col s12 m12 l6">
                                    <img src={image36} alt="Barrenness" className="materialboxed container" style={{ width: "100%" }} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s12 m12 l6">
                                    <h3 className="green-text text-darken-2 center">Family & Relationship Problems</h3>
                                    <p>All relationships and families go through difficult times and experiencing occasional problems and conflict in relationships is normal. However, sometimes these problems can become overwhelming. Bakongwa Doctor is a relationship and marriage counsellor based in Mjini Lubumbashi, DR Congo. Book an appointment today with the doctor if you have relationship and marriage problems.</p>
                                </div><br /><br /><br /><br />
                                <div className="col s12 m12 l6">
                                    <img src={image37} alt="Barrenness" className="materialboxed container" style={{ width: "100%" }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        )
    }
}