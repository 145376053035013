import React, { Component } from 'react'
import Whatsapp from './components/Whatsaap'
import Navbar from './components/Navbar'
import image81 from './components/Images/image81.jpg'
import Footer from './components/Footer'

export default class FinancialSolution extends Component {

    componentDidMount() {
        document.title = 'Bakongwa Doctors'
    }


    render() {
        return (
            <React.Fragment>
                <Navbar /><br />
                <Whatsapp />
                <div className="row grey lighten-5 row">
                    <div className="col s10 m10 l10 push-l1 push-s1 offset-m1">
                        <img src={image81} alt="image73" className="materialboxed col s12 m12" />
                        <span className="img-tittle green-text text-darken-2"><strong style={{ fontSize: "25px" }}>MY BOSS WANTS TO HAVE A CHILD WITH MY WIFE</strong></span>
                        <p>
                            Truly, when you marvel at the wonders of Moses, you'll be amazed at those of Pharaoh, is a saying that Swahili speakers of ancient times used, and it's a proverb that continues to resonate in people's lives and society in general.

                            I am a husband and father of four children, my name is Mustapha, and I decided to write this message after my boss brought up very strange issues, specifically insisting on having a child with my legally wedded wife. Bizarre!

                            This was a matter shrouded in heavy secrecy, but with the help of Bakongwa Doctors, I discovered the absurd tactics my boss was using. I remember a colleague giving me the contact of this specialist (+243 990 627 777) and telling me that he could bind my wife, preventing her from straying outside our marriage.

                            I visited his office, and Bakongwa Doctors provided me with medication to address the issue. I continued with my activities, while my boss kept assigning me numerous work trips to have more time to persuade my wife.

                            However, my wife was unaware that I had taken preventive measures, and my boss remained oblivious. Consequently, whenever I went on business trips, I had peace of mind and even more happiness, especially due to the increased financial compensation I received as a per diem for the travels.

                            After several months, my wife called me to our room and revealed that my boss had been making advances, expressing his desire to have a child with her because he found our children adorable.

                            My wife confessed that she had kept this secret for a long time, but she felt her heart losing peace with the growing weight of the situation.

                            She cried and asked for forgiveness for keeping such a bizarre secret that could have led her to bear a child outside our eight-year marriage.

                            At that point, I knew that Bakongwa Doctors' medicine indeed worked as intended. What surprised me was that my boss was eventually dismissed from his job under circumstances I still don't fully understand, and our marriage became even more secure.

                            Remember, Bakongwa Doctors has the ability to bring luck into someone's life, win lottery games, protect you from your enemies, help you win conflicts like land disputes, have romantic appeal, and increase sales in business.

                            He also treats diseases like syphilis, gonorrhea, tuberculosis, high blood pressure, epilepsy, erectile dysfunction, swollen legs, among others. Additionally, he helps women conceive and men impregnate women.</p>
                        <strong>Connect with Us:<a href="tel:+243 990 627 777" className="green-text text-darken-2" > Phone : +243 990 627 777</a></strong> | <strong><a href="mailto:bbakongwadoctors@gmail.com" className="green-text text-darken-2" >Email :bbakongwadoctors@gmail.com </a></strong>
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        )
    }
}