import React from 'react'
import M from 'materialize-css'
import Loader from '../../components/loader'
import Buton from '../../components/button'
import Input from '../../components/input'
import { servelURL } from '../../config'
import backgroundImage from '../../config/logo.jpg'
import { Link } from 'react-router-dom'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'

const Login = function (props) {

    const [account, setAccount] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [disabled, setDisabled] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [accountError, setAccountError] = React.useState('')
    const [passwordError, setPasswordError] = React.useState('')

    React.useEffect(function () {
        M.AutoInit()
        document.title = 'LogIn | Bakongwa Doctors'
        return function () { }
        // eslint-disable-next-line
    }, [])

    const validate = function (event) {
        try {
            event.preventDefault()
            const errors = []

            if ((account !== undefined) && (account.trim() === '')) {
                setAccountError('Please fill this field')
                errors.push("")
                setDisabled(true)
            }
            else if ((account !== undefined) && (account.length !== 10)) {
                setAccountError('Account should have ten digits')
                errors.push('')
                setDisabled(true)
            }

            if ((password !== undefined) && (password.trim() === '')) {
                setPasswordError('Please fill this field')
                errors.push('')
                setDisabled(true)
            }
            else if ((password !== undefined) && (password.length < 8)) {
                setPasswordError('Please fill this field')
                errors.push("")
                setDisabled(true)
            }

            if (errors.length === 0) {
                submit()
            }
            else setDisabled(true)
        }
        catch (error) {
            setDisabled(true)
            if (error instanceof Error) {
                new M.Toast({ html: error.message })
            }
            else console.log(error)
        }
    }

    async function submit() {
        try {
            setLoading(true)
            let response = await fetch(`${servelURL}/authenticate`, {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    schema: "user",
                    fieldWithEncryption: 'password',
                    condition: { phone: account },
                    joinForeignKeys: false,
                    select: {},
                    valueToCompareWithEncryption: password
                })
            })
            setLoading(false)
            response = await response.json()
            if (response.success) {
                new M.Toast({ html: 'LogIn successfully !' })
                props.history.push({
                    pathname: '/customer/view',
                    state: { account: response.message }
                })
                localStorage.setItem('data', btoa(JSON.stringify(response.message)))
            }
            else {
                setDisabled(true)
                new M.Toast({ html: response.message })
            }
        }
        catch (error) {
            setDisabled(true)
            if (error instanceof Error) {
                new M.Toast({ html: error.message })
            }
            else console.log(error)
        }
    }

    return (
        <main>
            <Navbar />
            {
                loading ? <Loader /> : null
            }
            <div
                className='background-image'
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundSize: 'cover',
                    opacity: 0.2,
                    zIndex: -1,
                }}
            >
            </div>
            <div className='row' style={{ marginTop: '2rem' }}>
                <div className='col s10 m4 push-m4 offset-s1'>
                    <div className='card hoverable'>
                        <div className='card-action center'>
                            <Link to={"/login"} className="active pink-text text-darken-3">Login</Link>
                            <Link to={"/login"} className="grey-text">Reset Password</Link>
                        </div>
                        <div className='image-container' style={{ display: 'flex', justifyContent: 'center' }}>
                            {/* <img src="./image.jpg" alt="pic" style={{ width: '100px', height: 'auto' }} /> */}
                        </div>
                        <form className='container' onSubmit={validate}>
                            <div className='card-content'>
                                <span className='card-title center'>Welcome Back</span>
                                <div className='row'>
                                    <div className='col s12'>
                                        <Input
                                            name='account'
                                            value={account}
                                            type='text'
                                            onChange={(event) => setAccount(event.target.value, setAccountError(''), setDisabled(false))}
                                            label='Account'
                                        />
                                        <span className='helper-text red-text'>{accountError ? accountError : ""}</span>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col s12'>
                                        <Input
                                            name='password'
                                            value={password}
                                            type='password'
                                            label='Password'
                                            onChange={(event) => setPassword(event.target.value, setPasswordError(''), setDisabled(false))}
                                        />
                                        <span className='helper-text red-text'>{passwordError ? passwordError : ""}</span>
                                    </div>
                                </div>
                            </div>
                            <div className='card-action center'>
                                <Buton
                                    className='btn-small green'
                                    disabled={disabled ? true : false}
                                    icon='send'
                                    type='send'
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </main>
    )
}

export default Login