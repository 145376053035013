import React, { Component } from 'react'
import Navbar from './components/Navbar'
import image52 from './components/Images/image52.jpg'
import Footer from './components/Footer'
import image15 from './components/Images/image15.jpg'
import image53 from './components/Images/image53.jpg'
import image54 from './components/Images/image54.jpg'
import image55 from './components/Images/image55.jpg'
import image56 from './components/Images/image56.jpg'
import image57 from './components/Images/image57.jpg'
import image58 from './components/Images/image58.jpg'
import image59 from './components/Images/image59.jpg'
import image60 from './components/Images/image60.jpg'
import image61 from './components/Images/image61.jpg'
import image62 from './components/Images/image62.jpg'
import image63 from './components/Images/image63.jpg'
import image64 from './components/Images/image64.jpg'
import image65 from './components/Images/image65.jpg'
import image66 from './components/Images/image66.jpg'
import image67 from './components/Images/image67.jpg'
import image68 from './components/Images/image68.jpg'
import image69 from './components/Images/image69.jpg'
import image70 from './components/Images/image70.png'
import image30 from './components/Images/image30.jpg'
import image32 from './components/Images/image32.jpg'
import image24 from './components/Images/image24.jpg'
import image28 from './components/Images/image28.jpg'
import image27 from './components/Images/image27.jpg'
import image23 from './components/Images/image23.jpg'
import { Link } from 'react-router-dom'
import Whatsapp from './components/Whatsaap'

export default class PowerfullSpell extends Component {

     componentDidMount() {
        document.title = 'Bakongwa Doctors'
    }

    
    render() {
        return (
            <React.Fragment>
                <Navbar /><br />
                <Whatsapp/>
                <div className="grey lighten-5">
                    <div className="row">
                        <div className="col s10 m10 l10 push-l1 push-s1 offset-m1">
                            <div className="row card">
                                <div className="col s12 m12 l6">
                                    <div className="card-image">
                                        <img src={image52} alt="money" className="materialboxed" />
                                        <span className="card-tittle center"><strong className="black-text">Free Money Spells</strong></span>
                                    </div>
                                </div>
                                <div className="col s12 m12 l6">
                                    <div className="card-image">
                                        <img src={image15} alt="money" className="materialboxed" />
                                        <span className="card-tittle center"><strong className="black-text">TALISMANS MAGICAL SPELL FOR INDIVIDUAL FAMILY</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div className="row card">
                                <div className="col s12 m12 l6">
                                    <div className="card-image">
                                        <img src={image53} alt="money" className="materialboxed" />
                                        <span className="card-tittle center"><strong className="black-text">FREE LOVE SPELLS</strong></span>
                                    </div>
                                </div>
                                <div className="col s12 m12 l6">
                                    <div className="card-image">
                                        <img src={image54} alt="money" className="materialboxed" />
                                        <span className="card-tittle center"><strong className="black-text">WHITE MAGIC SPELLS</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div className="row card">
                                <div className="col s12 m12 l6">
                                    <div className="card-image">
                                        <img src={image55} alt="money" className="materialboxed" />
                                        <span className="card-tittle center"><strong className="black-text">GENIES SPELLS POWER OF SUBCONSCIOUS MIND</strong></span>
                                    </div>
                                </div>
                                <div className="col s12 m12 l6">
                                    <div className="card-image">
                                        <img src={image56} alt="money" className="materialboxed" />
                                        <span className="card-tittle center"><strong className="black-text">WITCHCRAFT SPELLS PROVIDE MAGICAL POTENT HELP TO YOUR CIRCUMSTANCES</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div className="row card">
                                <div className="col s12 m12 l6">
                                    <div className="card-image">
                                        <img src={image57} alt="money" className="materialboxed" />
                                        <span className="card-tittle center"><strong className="black-text">SPIRITS SPELLS PROTECT YOURSELF FROM NEGATIVE-ENERGY</strong></span>
                                    </div>
                                </div>
                                <div className="col s12 m12 l6">
                                    <div className="card-image">
                                        <img src={image58} alt="money" className="materialboxed" />
                                        <span className="card-tittle center"><strong className="black-text">MARRIAGE SPELLS THAT WORK</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div className="row card">
                                <div className="col s12 m12 l6">
                                    <div className="card-image">
                                        <img src={image59} alt="money" className="materialboxed" />
                                        <span className="card-tittle center"><strong className="black-text">PREGNENCY SPELLS WORK LIKE WONDERS</strong></span>
                                    </div>
                                </div>
                                <div className="col s12 m12 l6">
                                    <div className="card-image">
                                        <img src={image60} alt="money" className="materialboxed" />
                                        <span className="card-tittle center"><strong className="black-text">POWERFUL PROSPERITY SPELLS ONLINE</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div className="row card">
                                <div className="col s12 m12 l6">
                                    <div className="card-image">
                                        <img src={image61} alt="money" className="materialboxed" />
                                        <span className="card-tittle center"><strong className="black-text">REVENGE SPELLS CASTER INDIA</strong></span>
                                    </div>
                                </div>
                                <div className="col s12 m12 l6">
                                    <div className="card-image">
                                        <img src={image62} alt="money" className="materialboxed" />
                                        <span className="card-tittle center"><strong className="black-text">POWERFUL BLACK MAGIC SPELLS FOR LOVE, SUCCESS & PROTECTION</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div className="row card">
                                <div className="col s12 m12 l6">
                                    <div className="card-image">
                                        <img src={image63} alt="money" className="materialboxed" />
                                        <span className="card-tittle center"><strong className="black-text">Cast Voodoo Spells</strong></span>
                                    </div>
                                </div>
                                <div className="col s12 m12 l6">
                                    <div className="card-image">
                                        <img src={image64} alt="money" className="materialboxed" />
                                        <span className="card-tittle center"><strong className="black-text">Good Luck Spells</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div className="row card">
                                <div className="col s12 m12 l6">
                                    <div className="card-image">
                                        <img src={image65} alt="money" className="materialboxed" />
                                        <span className="card-tittle center"><strong className="black-text">MONEY SPELLS WITH CANDLES</strong></span>
                                    </div>
                                </div>
                                <div className="col s12 m12 l6">
                                    <div className="card-image">
                                        <img src={image66} alt="money" className="materialboxed" />
                                        <span className="card-tittle center"><strong className="black-text">MAGICK-SPELLS</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div className="row card">
                                <div className="col s12 m12 l6">
                                    <div className="card-image">
                                        <img src={image67} alt="money" className="materialboxed" />
                                        <span className="card-tittle center"><strong className="black-text">magic spell and potions</strong></span>
                                    </div>
                                </div>
                                <div className="col s12 m12 l6">
                                    <div className="card-image">
                                        <img src={image68} alt="money" className="materialboxed" />
                                        <span className="card-tittle center"><strong className="black-text">love-spells-charms-talismans-amulet-potions</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div className="row card">
                                <div className="col s12 m12 l6">
                                    <div className="card-image">
                                        <img src={image69} alt="money" className="materialboxed" />
                                        <span className="card-tittle center"><strong className="black-text">Black Magic Protection Spells</strong></span>
                                    </div>
                                </div>
                                <div className="col s12 m12 l6">
                                    <div className="card-image">
                                        <img src={image70} alt="money" className="materialboxed" />
                                        <span className="card-tittle center"><strong className="black-text">Power of Mind and Brain</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div className="row card">
                                <div className="col s12 m12 l6">
                                    <div className="card-image">
                                        <img src={image30} alt="money" className="materialboxed" />
                                        <span className="card-tittle center"><strong className="black-text">Barrenness</strong></span>
                                    </div>
                                </div>
                                <div className="col s12 m12 l6">
                                    <div className="card-image">
                                        <img src={image32} alt="money" className="materialboxed" />
                                        <span className="card-tittle center"><strong className="black-text">Nightmares</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div className="row card">
                                <div className="col s12 m12 l6">
                                    <div className="card-image">
                                        <img src={image24} alt="money" className="materialboxed" />
                                        <span className="card-tittle center"><strong className="black-text">Family Curses</strong></span>
                                    </div>
                                </div>
                                <div className="col s12 m12 l6">
                                    <div className="card-image">
                                        <img src={image28} alt="money" className="materialboxed" />
                                        <span className="card-tittle center"><strong className="black-text">Penis Enlargement</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div className="row card">
                                <div className="col s12 m12 l6">
                                    <div className="card-image">
                                        <img src={image27} alt="money" className="materialboxed" />
                                        <span className="card-tittle center"><strong className="black-text">Enemies</strong></span>
                                    </div>
                                </div>
                                <div className="col s12 m12 l6">
                                    <div className="card-image">
                                        <img src={image23} alt="money" className="materialboxed" />
                                        <span className="card-tittle center"><strong className="black-text">Pass Job Interviews</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="center">
                                    <Link to='/Spells'><strong className="green-text text-darken-2" style={{ 'fontSize': "40px" }}>More Spells Here</strong></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        )
    }
}