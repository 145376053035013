import React, { Component } from 'react'
import Whatsapp from './components/Whatsaap'
import Navbar from './components/Navbar'
import image75 from './components/Images/image755.jpg'
import Footer from './components/Footer'

export default class LoveSolution extends Component {

    componentDidMount() {
        document.title = 'Bakongwa Doctors'
    }


    render() {
        return (
            <React.Fragment>
                <Navbar /><br />
                <Whatsapp />
                <div className="row grey lighten-5 row">
                    <div className="col s10 m10 l10 push-l1 push-s1 offset-m1">
                        <img src={image75} alt="image73" className="materialboxed col s12 m12" />
                        <span className="img-tittle green-text"><strong style={{ fontSize: "25px" }}>PROGRESS IN LIFE SOLUTION</strong></span>
                        <p>
                            In life, I have come to realize that you can work very hard and be dedicated, but it may be challenging for you to succeed because you may be struggling in an area where your livelihood is not destined to be. My name is Shabani, a resident of Mombasa, Kenya. Since I was a young boy, I decided to engage in fishing because I loved sea voyages. I worked in this profession for many years, and soon, everyone in our neighborhood knew me as a renowned fisherman.

                            My challenge began when, despite working hard and being well-known and praised by everyone, I did not see any progress in my life due to my chosen profession.

                            I started facing mental stress because I wanted to get married. I already had a fiancée, but I was unable to understand how I would support my wife without any significant progress in my life.

                            The idea struck me to look for my success star, as if fishing had not yielded any meaningful results for over ten years, I had nothing substantial to boast about. I searched online for experts in star matters until I came across the website www.bakongwadoctors.com of Bakongwa Doctors, whom I dare to call the hero of my life. On his website, I took his number, +243 990 627 777, and called him. He invited me to his office in Kericho. After reaching there and consulting with his expertise, he told me that my success star was not in fishing but rather in buying fish from fishermen and selling to customers.

                            Finally, I left for home. I didn't have capital, but since many fishermen were my friends, I decided to borrow and sell the fish. After a week, I already had enough capital to buy fish. This business has brought me success to the point where I could build, and now I have three children, all attending good schools.

                            Since then, I have personally believed a lot in stars. Even my friends who have faced life challenges several times, I have advised them to go and consult Bakongwa Doctors, who is the most trustworthy expert in star matters..</p>
                        <strong>Connect with Us:<a href="tel:+243 990 627 777" className="green-text" > Phone : +243 990 627 777</a></strong> | <strong><a href="mailto:bbakongwadoctors@gmail.com" className="green-text" >Email :bbakongwadoctors@gmail.com </a></strong>
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        )
    }
}