import React, { Component } from 'react'
import Navbar from './components/Navbar'
import Part from './components/Part'
import Ring from './components/Ring'
import Spell from './components/Spell'
import Whatsapp from './components/Whatsaap'
// import image73 from './components/Images/image73.jpg'
import image72 from './components/Images/jela.jpg'
import image71 from './components/Images/image71.webp'
import { Link } from 'react-router-dom'
import Marquee from 'react-fast-marquee'
import imagex from './components/Images/imagex.jpg'
import image88 from './components/Images/image88.jpg'
import image75 from './components/Images/image755.jpg'
import image001 from './components/Images/image001.jpg'
import image77 from './components/Images/image77.jpg'
import image78 from './components/Images/image788.jpg'
import image79 from './components/Images/image79.jpg'
import image80 from './components/Images/image80.jpg'
import image81 from './components/Images/image81.jpg'
import image82 from './components/Images/image82.webp'
import image83 from './components/Images/image83.jpg'
import image84 from './components/Images/image84.jpg'
import image85 from './components/Images/image85.jpg'
import image86 from './components/Images/image86.jpg'
import image74 from './components/Images/image74.jpg'
import emailjs from 'emailjs-com'
import image90 from './components/Images/image90.jpg'
import image91 from './components/Images/image91.jpg'
import image89 from './components/Images/imag89.jpg'
import M from 'materialize-css'
import image from './components/Images/image.jpg'
import image1 from './components/Images/image1.jpg'
import image2 from './components/Images/image2.jpg'
import image92 from './components/Images/image92.jpg'
import Footer from './components/Footer'
import { init } from 'emailjs-com';
init("MD7C4ZGC3jNxr0dg7");

export default class Muringo extends Component {

    componentDidMount() {
        document.title = 'Bakongwa Doctors'
    }

    state = {
        user_name: "",
        user_email: "",
        problem: "",
        location: "",
        message: "",
        phone: "",
        user_nameerror: "",
        user_emailerror: "",
        problemerror: "",
        locationerror: "",
        messageerror: "",
        phoneerror: "",
        disabled: false
    }

    Validate = event => {
        var errors = []
        event.preventDefault()
        this.setState({
            disabled: true
        })
        if (!this.state.user_email) {
            errors.push("")
            this.setState({
                user_emailerror: "this field is required"
            })
        }
        if (!this.state.user_name) {
            errors.push("")
            this.setState({
                user_nameerror: "this field is required"
            })
        }
        if (!this.state.phone) {
            errors.push("")
            this.setState({
                phoneerror: "this field is required"
            })
        }
        if (!this.state.message) {
            errors.push("")
            this.setState({
                messageerror: "this field is required"
            })
        }
        if (!this.state.location) {
            errors.push("")
            this.setState({
                locationerror: "this field is required"
            })
        }
        if (!this.state.problem) {
            errors.push("")
            this.setState({
                problemerror: "this field is required"
            })
        }
        if (errors.length === 0) {
            this.HandleSubmit(event)
        }
    }

    HandleChange = event => {
        var { name, value } = event.target
        this.setState({
            [name]: value,
            user_nameerror: "",
            user_emailerror: "",
            problemerror: "",
            locationerror: "",
            messageerror: "",
            phoneerror: "",
            disabled: false
        })
    }

    HandleSubmit = event => {
        event.preventDefault()
        this.setState({
            disabled: false
        })
        emailjs
            .sendForm(
                'service_40ystbo',
                'template_uvrxgvj',
                event.target
            )
            .then((result) => {
                if (result) {
                    this.setState({
                        user_email: "",
                        user_name: "",
                        problem: "",
                        phone: "",
                        location: "",
                        message: ""
                    })
                    M.toast({ html: "Thank you for your message", displayLength: 6000 })
                }
                else {
                    M.toast({ html: "Something went wrong.Try again" })
                }
            })
            .catch((error) => {
                M.toast({ html: "Failed to send the message" })
            })
    }

    render() {
        return (
            <React.Fragment>
                <Navbar />
                <div className="slider teal lighten-5">
                    <ul className="slides">
                        <li>
                            <img src={image1} className=" materialboxed" width="1000" alt="Bakongwa Doctors" />
                            <div className="caption center-align">
                                <h2>GET YOUR SPELL FOR LIFE!</h2>
                                <a href="https://wa.me://+243990627777" className="waves-effect waves-light btn-large col s5 offset-s3 green darken-2">CALL BAKONGWA NOW</a>
                            </div>
                        </li>
                        <li>
                            <img src={imagex} className=" materialboxed" width="1000" alt="The best healer of all the time" />
                            <div className="caption left-align">
                                <h2 style={{ color: "white" }}>BAKONGWA DOCTORS</h2>
                                <h5 className="white-text">Best doctor across East & Central Africa .</h5>
                                <a href="/Spells" className="waves-effect waves-light btn-large col s5 offset-s3 green darken-2">Get your Spell Now</a>
                            </div>
                        </li>
                        <li>
                            <img src={image2} className=" materialboxed" width="1000" alt="The best healer of all the time" />
                            <div className="caption right-align">
                                <h4>Money Spells , Magic Rings & Love Spells</h4>
                            </div>
                        </li>
                        <li>
                            <img src={image} className=" materialboxed" width="1000" alt="The best healer of all the time" />
                            <div className="caption right-align">
                                <h3>Traditional Herbs</h3>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="row spell">
                    <div className="col s10 m10 l10 push-l1 offset-s1 push-m1">
                        <div className="grey lighten-5">
                            <div className="card row spell">
                                <div className="card-image col s12 m12 l4">
                                    <h5 className="green-text text-darken-2">HOW MY FIRST JOB INTERVIEW GAVE ME A WELL PAYING JOB AS THE BANK MANAGER IN ONE BIG BANKS IN UGANDA.</h5>
                                    <img src={image82} alt="image73" className="container materialboxed" />
                                    {/* <h7>Upendo wa baba ulivyo igharimu familia</h7> */}
                                    <p>Iam a graduate with degree with lots of hope ahead of me was going to be good having worked really  hard in my studies I wanted to see the fruits back

                                        • Before joining university I passed highly with a good grade that landed me to do banking and  management a course I was so passionate about it was going to change my life indeed.

                                        I had  been brought in a humbled background,[…]<br /><br />
                                        <Link to='/upendoSolution' rel="noopener noreferrer" target="_blank" className="btn-small green darken-2">Read More</Link>
                                    </p>
                                    {/* <h5 className="green-text text-darken-2">SHE FINALLY GOT MARRIED, SHE HAD BEEN EAGERLY WAITING FOR THIS OPPORTUNITY.</h5>
                                    <img src={image73} alt="image73" className="container materialboxed" />
                                    <p> A certain Kenyan lady once elicited netizens after she had been seen walking with a banner which read “I need someone to marry me as I’m aging” most people especially of the male gender saw this as really ridiculous as it was not the usual state seeing a lady seek for a husband in the open. Some men just took photos of her but never made any approach of trying to talk to [...] <br /><br />
                                        <Link to='/Married' rel="noopener noreferrer" target="_blank" className="btn-small green darken-2 " >Read mORE</Link>
                                    </p> */}
                                </div>
                                <div className="card-image col s12 m12 l4">
                                    <h5 className="green-text text-darken-2">I HAVE BEEN CHARGED WITH THE RAPE OF MY SISTER-IN-LAW</h5>
                                    <img src={image72} alt="image73" className="container materialboxed" />
                                    <p>My name is George from Kakamega, and in my life, I have learned that someone you trust so much can do something very harmful to you, although there are also good people.

                                        I had a girlfriend with whom we loved each other so much that we started making plans to get married in the future. However, it all came to a terrible end for me, something I can never forget.

                                        This happened after my girlfriend accused me<br /><br />
                                        <Link to='/Drug' rel="noopener noreferrer" target="_blank" className="btn-small green darken-2">Read More</Link>
                                    </p>
                                </div>
                                <div className="card-image col s12 m12 l4">
                                    <h5 className="green-text text-darken-2">I AM A 28 YEAR OLD FEMALE, AND I HAVE REACHED THIS WITHOUT DEVELOPING BREASTS!</h5>
                                    <img src={image71} alt="image73" className="container materialboxed" />
                                    <p> My name is Aisha, a resident of Tabora town. I am a very beautiful girl, and I say this because every time I walk by, men turn their heads to look at me, sometimes due to my figure, color, and [...]<br /><br />
                                        <Link to='/Fear' rel="noopener noreferrer" target="_blank" className="btn-small green darken-2">Read More</Link>
                                    </p>
                                </div>
                            </div>
                            <Part /><br /><br />
                            <Ring /><br /><br />
                            <Spell />
                            <Whatsapp />
                            <Marquee direction="right" speed="67" style={{ fontSize: "35px" }} className="green-text text-darken-2" ><h5 className="green-text text-darken-2 center" >TOP SOLUTIONS</h5></Marquee>
                            <div className="card row spell grey lighten-4">
                                <div className="col s12 m12 l12">
                                    <div className="card-image col s12 m12 l4">
                                        <h5 className="green-text text-darken-2">Marriage Solution.</h5>
                                        <img src={image78} alt="image73" className="container materialboxed" />
                                        <h7>This medicine has enabled me to enjoy eatnig the fruits well now!</h7>
                                        <p>The truth is that a man's true honor from his wife comes when he fulfills his responsibilities well in taking care of the family, and most importantly, during dinner, a husband should ensure that the wife eats and is satisfied.

                                            As grown-ups, we understand each other well in this most sensitive matter; it's our battlefield to boast, showcase our abilities, and prove   […]<br /><br />
                                            <Link to='/MarriageSolution' rel="noopener noreferrer" target="_blank" className="btn-small green darken-2">Read More</Link>
                                        </p>
                                    </div>
                                    <div className="card-image col s12 m12 l4">
                                        <h5 className="green-text text-darken-2">Money saving solution.</h5>
                                        <img src={image90} alt="image73" className="container materialboxed" />
                                        <h7>I was played not to keep money in my pocket</h7>
                                        <p>I am doing a good job at a non-governmental organization that helps orphans, the elderly and all the underprivileged in Dodoma, our organization is called Diamanda Foundation, it is not very old, it is about […]<br /><br />
                                            <Link to='/Addiction' rel="noopener noreferrer" target="_blank" className="btn-small green darken-2">Read More</Link>
                                        </p>
                                    </div>

                                    <div className="card-image col s12 m12 l4">
                                        <h5 className="green-text text-darken-2">Pregnant Solution</h5>
                                        <img src={image80} alt="image73" className="container materialboxed" />
                                        <h7>I was able to get pregnant after struggling for a long time.</h7>
                                        <p>In my life, I struggled for many years to conceive. I visited every hospital, tried all kinds of medicines, especially herbal remedies, but I couldn't get pregnant.

                                            My name is Anna, and I got married seven years ago. Initially, we were deeply in love with my husband to the point that even our neighbors admired our marriage. However […]<br /><br />
                                            <Link to='/EducationSolution' rel="noopener noreferrer" target="_blank" className="btn-small green darken-2">Read More</Link>
                                        </p>
                                    </div>
                                </div>
                                <div className="col s12 m12 l12">
                                    <div className="card-image col s12 m12 l4">
                                        <h5 className="green-text text-darken-2">Progress in life Solution.</h5>
                                        <img src={image75} alt="image73" className="container materialboxed" />
                                        <h7>If you know this one secret, you will succeed greatly in life!
                                        </h7>
                                        <p> In life, I have come to realize that you can work very hard and be dedicated, but it may be challenging for you to succeed because you may be struggling in an area where your livelihood is not destined to be. My name is Shabani, a resident of Mombasa, Kenya. Since I was a young boy, I decided to engage in fishing because […]
                                            <br /><br />
                                            <Link to='/LoveSolution' rel="noopener noreferrer" target="_blank" className="btn-small green darken-2">Read More</Link>
                                        </p>
                                    </div>
                                    <div className="card-image col s12 m12 l4">
                                        <h5 className="green-text text-darken-2">Protection in business</h5>
                                        <img src={image001} alt="image73" className="container materialboxed" />
                                        <h7>I found thieves sleeping in my shop due to the protection measures I have in my business.</h7>
                                        <p>My name is Joe, and at one point, I was robbed in my store, which set me back significantly. This forced me to abruptly close my business due to a lack of funds to sustain it.

                                            The incident deeply hurt me, and since I couldn't apprehend the thieves and mete out justice myself, I went to the police station to report the incident in the hope of recovering my stolen goods.

                                            It's been a year now […]<br /><br />
                                            <Link to='/BusinessSolution' rel="noopener noreferrer" target="_blank" className="btn-small green darken-2 ">Read More</Link>
                                        </p>
                                    </div>
                                    <div className="card-image col s12 m12 l4">
                                        <h5 className="green-text text-darken-2">WitchCraft Solution</h5>
                                        <img src={image77} alt="image73" className="container materialboxed" />
                                        <h7>He took off my underwear so that i wouldn't be married after rejecting him</h7>
                                        <p>James was a young man who lived in our neighborhood of Buza. We got to know each other and met regularly. One day, as I was leaving the Buza Stand to go home, I encountered James, and he asked for my phone number. Without hesitation, I gave it to him.

                                            That night, James called me and […]<br /><br />
                                            <Link to='/WitchCraftSolution' rel="noopener noreferrer" target="_blank" className="btn-small green darken-2">Read More</Link>
                                        </p>
                                    </div>
                                </div>
                                <div className="col s12 m12 l12">
                                    <div className="card-image col s12 m12 l4">
                                        <h5 className="green-text text-darken-2">Solution for blemishes and scars</h5>
                                        <img src={image79} alt="image73" className="container materialboxed" />
                                        <h7>Remove blemishes and scars on the body: having smooth and attractive skin</h7>
                                        <p>My face was so damaged that I wouldn't leave my house, but now I have healed!

                                            My name is Anyango from the Kisumu region. I used to be a model who participated in competitions in this East African region regularly. I didn't want anything to bother me because that would hinder my popularity. I had won titles in Uganda, and as time went by […]<br /><br />
                                            <Link to='/FinancialSolution' rel="noopener noreferrer" target="_blank" className="btn-small green darken-2">Read More</Link>
                                        </p>
                                    </div>
                                    <div className="card-image col s12 m12 l4">
                                        <h5 className="green-text text-darken-2">Lottery Solution
                                            .</h5>
                                        <img src={image91} alt="image73" className="container materialboxed" />
                                        <h7>The strategy i used to win bets has now made me a big millionaire</h7>
                                        <p>In this world, there are many ways to succeed in life, but the challenge is how to discover and utilize those ways to achieve success.

                                            Unfortunately, very few successful people are willing to share the true secrets of their success. Most prefer to keep their achievements a secret, fearing competition or unwanted attention.

                                            One seemingly easy way […]<br /><br />
                                            <Link to='/lostSolution' rel="noopener noreferrer" target="_blank" className="btn-small green darken-2">Read More</Link>
                                        </p>
                                    </div>
                                    <div className="card-image col s12 m12 l4">
                                        <h5 className="green-text text-darken-2">Strategeis to succees in studies</h5>
                                        <img src={image83} alt="image73" className="container materialboxed" />
                                        <h7>Strategeies to make children succeed in their studies</h7>
                                        <p>The truth is that every parent, when taking their child to school, expects them to perform well in their studies and pass exams so that they can progress and eventually pursue a certain profession that will help them in their life.

                                            You always know that nothing challenges most parents like when they spend a lot of money to pay their child's school fees, but the child keeps failing all the time. This is a very  […]<br /><br />
                                            <Link to='/LuckCharmSolution' rel="noopener noreferrer" target="_blank" className="btn-small green darken-2">Read More</Link>
                                        </p>
                                    </div>
                                </div>
                                <div className="col s12 m12 l12">
                                    <div className="card-image col s12 m12 l4">
                                        <h5 className="green-text text-darken-2">Solution to being Scammed.</h5>
                                        <img src={image84} alt="image73" className="container materialboxed" />
                                        <h7>
                                            I was scammed and cheated out of my inheritance, this is how Bakongwa helped me.</h7>
                                        <p>They wanted to take away our inherited house, but we won the case!

                                            My name is Yamola, and I am 20 years old, the firstborn in our family of eight boys and five girls.

                                            In 2017, our parents died in a car accident, causing immense sorrow. I was still in college, and my younger siblings were too young, some just starting their education.

                                            After the burial, some rel […]<br /><br />
                                            <Link to='/JobSolution' rel="noopener noreferrer" target="_blank" className="btn-small green darken-2">Read More</Link>
                                        </p>
                                    </div>
                                    <div className="card-image col s12 m12 l4">
                                        <h5 className="green-text text-darken-2">Birth giving solution</h5>
                                        <img src={image89} alt="image73" className="container materialboxed" />
                                        <h7>The mother gets a boy by doing this .</h7>
                                        <p>"My name is Witness, those who envy me call me Wit, I was lucky enough to marry the son of our boss at the company I work for in Meru. A lavish wedding was held in which our colleagues attended and gave many gifts.<br /><br />
                                            <Link to='/SexualDesire' rel="noopener noreferrer" target="_blank" className="btn-small green darken-2">Read More</Link>
                                        </p>
                                    </div>
                                    <div className="card-image col s12 m12 l4">
                                        <h5 className="green-text text-darken-2">Win a court case</h5>
                                        <img src={image86} alt="image73" className="container materialboxed" />
                                        <h7>Know the simple strategies for winning a court case thoroughly.</h7>
                                        <p>Due to some dishonesty among the judiciary officials and their willingness to accept bribes, some people have been unable to receive the justice they deserve in this crucial institution.

                                            In this world, there are individuals known for using their money to bribe lawyers and judges to win cases, and they have succeeded to a large extent.

                                            My name is Hu[…]<br /><br />
                                            <Link to='/PenisSolution' rel="noopener noreferrer" target="_blank" className="btn-small green darken-2">Read More</Link>
                                        </p>
                                    </div>
                                </div>
                                <div className="col s12 m12 l12">
                                    <div className="card-image col s12 m12 l4">
                                        <h5 className="green-text text-darken-2">Long lost love solution.</h5>
                                        <img src={image88} alt="image73" className="container materialboxed" />
                                        <h7>
                                            Get to find someone you genuinely love each other.</h7>
                                        <p>I compare love to a garden that requires constant watering. Without sufficient water, it may wither and eventually die. Love needs the water of affection every day to flourish.

                                            My name is Maria, a resident of Kinondoni. I began my journey with love at the age of 20, and my first boyfr […]<br /><br />
                                            <Link to='/Miscariage' rel="noopener noreferrer" target="_blank" className="btn-small green darken-2">Read More</Link>
                                        </p>
                                    </div>
                                    <div className="card-image col s12 m12 l4">
                                        <h5 className="green-text text-darken-2">Work-Related Solution.</h5>
                                        <img src={image81} alt="image73" className="container materialboxed" />
                                        <h7>
                                            My boss wants to have a child with my wife.</h7>
                                        <p>Truly, when you marvel at the wonders of Moses, you'll be amazed at those of Pharaoh, is a saying that Swahili speakers of ancient times used, and it's a proverb that continues to resonate in people's lives and society in general.
                                            I am a husband and father of four children, my name is Mustapha, and I decided to write this <br /><br />
                                            <Link to='/WorkRelatedSolution' rel="noopener noreferrer" target="_blank" className="btn-small green darken-2">Read More</Link>
                                        </p>
                                    </div>
                                    <div className="card-image col s12 m12 l4">
                                        <h5 className="green-text text-darken-2">Enemies Solution</h5>
                                        <img src={image85} alt="image73" className="container materialboxed" />
                                        <h7>I was raped and infected with HIV by my wicked uncle when I was a little girl (Emotional)</h7>
                                        <p>My name is Laura. I was left orphaned at the age of 16 and I had to move in with my uncle so that I could continue going to school. However, when I moved in with him, life became a living hell because he would rape every night when his wife was dead asleep. Though […]<br /><br />
                                            <Link to='/EnemiesSolution' rel="noopener noreferrer" target="_blank" className="btn-small green darken-2">Read More</Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s12 m12 l12">
                                    <Link to='/Inside' rel="noopener noreferrer" target="_blank" > <img src={image74} className="col s12 m12 l12" alt="image74" /> </Link>
                                </div>
                            </div>
                            <div className="grey lighten-4">
                                <strong className="center"><h5>CONTACT US</h5></strong>
                                <div className="row">
                                    <div className="col s12 m12 l5">
                                        <div className="row">
                                            <div className="col l11 push-l1">
                                                <h5 className="center" style={{ fontSize: "22px" }}>Talk To Us Now</h5>
                                                <h5 className="center" style={{ fontSize: "15px" }}>Bakongwa Doctors</h5>
                                                Location : <strong>Kenya, Tanzania, Uganda, Rwanda, Burundi, Sudan, DR COngo & Africa.</strong><br />
                                                Whatsapp Number : <a href="https://wa.me://+243990627777">+243 990 627 777</a><br />
                                                Email : <a href="mailto:bbakongwadoctors@gmail.com">bbakongwa@gmail.com</a>    <br />
                                            </div>
                                        </div>
                                        <h6 className="center" style={{ fontSize: "22px" }}>Working Hours</h6>
                                        <div className="row">
                                            <div className="col l8 push-l2">
                                                Monday to Saturday (From 7am to 4pm)<br />
                                                Consultation Fee : $20
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card col s12 m12 l6 ">
                                        <form className="contact-form" onSubmit={this.Validate}>
                                            <div>
                                                <label>Name</label>
                                                <input
                                                    type="text"
                                                    name="user_name"
                                                    value={this.state.user_name}
                                                    onChange={this.HandleChange}
                                                />
                                                <span className="helper-text red-text" >{this.state.user_nameerror}</span>
                                            </div>
                                            <div>
                                                <label>Email</label>
                                                <input
                                                    type="email"
                                                    name="user_email"
                                                    value={this.state.user_email}
                                                    onChange={this.HandleChange}
                                                />
                                                <span className="helper-text red-text" >{this.state.user_emailerror}</span>
                                            </div>
                                            <div>
                                                <label>Problem</label>
                                                <input
                                                    type="text"
                                                    name="problem"
                                                    onChange={this.HandleChange}
                                                    value={this.state.problem}
                                                />
                                                <span className="helper-text red-text" >{this.state.problemerror}</span>
                                            </div>
                                            <div>
                                                <label>Phone Number</label>
                                                <input
                                                    type="tel"
                                                    name="phone"
                                                    value={this.state.phone}
                                                    onChange={this.HandleChange}
                                                />
                                                <span className="helper-text red-text" >{this.state.phoneerror}</span>
                                            </div>
                                            <div>
                                                <label>Location</label>
                                                <input
                                                    type="text"
                                                    name="location"
                                                    value={this.state.location}
                                                    onChange={this.HandleChange}
                                                />
                                                <span className="helper-text red-text" >{this.state.locationerror}</span>
                                            </div>
                                            <div>
                                                <label>Message</label>
                                                <textarea
                                                    name="message"
                                                    value={this.state.message}
                                                    onChange={this.HandleChange}
                                                />
                                                <span className="helper-text red-text" >{this.state.messageerror}</span>
                                            </div>
                                            <div className="card-action center" >
                                                <input
                                                    type="submit"
                                                    value="Send"
                                                    className="btn-small green darken-2"
                                                    disabled={this.state.disabled}
                                                />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <h5 className="center"><a href='/Appointment' className="btn-large green darken-2"><strong>Make Appointment</strong></a></h5>
                        </div>
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        )
    }
}