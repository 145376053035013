import React, { Component } from 'react'
import Navbar from './components/Navbar'
import Footer from './components/Footer'

export default class Inside extends Component {

     componentDidMount() {
        document.title = 'Bakongwa Doctors'
    }

    
    render() {
        return (
            <React.Fragment>
                <Navbar />
                <div className="grey lighten-4">
                    <h5 className="green-text center" style={{ 'fontSize': "40px" }} ><b>WORDS FROM OUR LAST PATIENTS</b></h5>
                    <div className="row">
                        <div className="col s10 m10 l10 offset-l1 push-s1 push-m1 card-panel">
                            <div className="row"><br />
                                <div className="col s12 m12 l4">
                                    <span>"I highly recommend the Bakongwa doctors to you for whatever problems you are experiencing whether is love, protection, money or psychic powers. Bakongwa Doctors are the real deal. When I had over a thousand and one problems, Bakongwa Doctors solved then in a span of Minutes. Thank you so Much Bakongwa Doctors!"</span><br />
                                    <i className="green-text">Wollen Hakimu - Tanzania</i>
                                </div>
                                <div className="col s12 m12 l4">
                                    <span>"I have had amazing things happen during the first week of my casting. Mdaki has done a 360 and is now talking with me and wanting to spend time with me again. I can't believe after all that has happened he actually turned around and started talking and wanting to be with me. It's AMAZING!  You are like a god sends an angel in my life. What can I say? Rehema and he just split up and he wants now to be with me. Thank you very much for your help Bakongwa Doctor."</span><br />
                                    <i className="green-text">Aneth Joshua- Uganda</i>
                                </div>
                                <div className="col s12 m12 l4">
                                    <span>"My neck doesn't hurt any more" I am forever grateful for your help Doctor Bakongwa! Kindness and patience is with me. The pain I was feeling in my neck completely disappeared like you had promised me and I don't feel all that negative energy around me. You are the best Doctor Bakongwa! Thank you thank you, thank you, a thousand times."</span><br />
                                    <i className="green-text">Elton Bryson - Sudan</i>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s12 m12 l4">
                                    <span>"Highly impressive experience being my first contact with Papworth.  A bit frustrated with delay before treatments but understandable over the bank holiday.  Many thanks to all for their kindness and special Thanks to you Doctor Bakongwa."</span><br />
                                    <i className="green-text">Omary Buwa- Tanzania</i>
                                </div>
                                <div className="col s12 m12 l4">
                                    <span>"Sitosahau jinsi Bakongwa alivyotatuwa shida zangu.Nimekuwa nikipambana sana kweye hali yangu ndogo ya maisha ila kwa kila kitu nilichokuwa napata sikuweza hata kulipia pango mpaka nilipoambiwa niende kwa daktari  Bakongwa na kugunduwa kuwa dada yangu ndiye aliyekuwa anatumia nyota yangu. Kwasasa nina endelea vizuri kabisa na shida zote nimetatuliwa na daktari . Asante sana Daktari Bakongwa"</span><br />
                                    <i className="green-text">Amina Fred - Tanzania</i>
                                </div>
                                <div className="col s12 m12 l4">
                                    <span>"Bakongwa was amazing, kept us calm and fixd all my problems perfectly, and when I feared  and panicked he put me at ease.  Every one needs a Bakongwa! . Appreciate much Bakongwa. "</span><br />
                                    <i className="green-text">Vidia Bryson- Tanzania</i>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s12 m12 l4">
                                    <span>"I highly recommend the Bakongwa doctors to you for whatever problems you are experiencing whether is love, protection, money or psychic powers. Bakongwa Doctors are the real deal. When I had over a thousand and one problems, Bakongwa Doctors solved then in a span of Minutes. Thank you so Much Bakongwa Doctors!"</span><br />
                                    <i className="green-text">Victor Ndumba - Burundi</i>
                                </div>
                                <div className="col s12 m12 l4">
                                    <span>"I can say wholeheartedly that your doctors - all of them, are your greatest asset.  Everyone is happy to work here and take pride in looking after us - even when we are at our most demanding.  The team spirit is obvious and impressive.  I sincerely hope that you manage to keep it after the move.  The system works smoothly and efficiently.  Everyone can be proud.  Thank you all."</span><br />
                                    <i className="green-text">Jonathan Joshua - Rwanda</i>
                                </div>
                                <div className="col s12 m12 l4">
                                    <span>"Everything was carried out to the highest possible standard. Every member of them treated me with the utmost respect and above all, genuine kindness. Bakongwa Doctors in my experience is a model of the best possible solutions.  Thank you all so much for everything."</span><br />
                                    <i className="green-text">Hekima Peter - DR Congo</i>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s12 m12 l4">
                                    <span>"My immediate family lives in Israel and although I have many friends and colleagues in Kenya, this health set back left me depressed, anxious, physically ill and helpless. The emotional support, professional attention, extreme devotion exhibited to me by your staff, with guidance from God, allowed me to recover. I have never felt so loved, I have never seen such concern, nor have I ever met a more intelligent and understanding doctors compared to those of Bakongwa's. Thank you in advance doctor Bakongwa"</span><br />
                                    <i className="green-text">Adam Kitenda - Uganda</i>
                                </div>
                                <div className="col s12 m12 l4">
                                    <span>"My mother, Hanna Zuckerman …. was in a department for long-term rehab. It was really surprising to see how respectful, caring and considerate the doctors are. It was amazing to see how patient and the aides are. I have seen all this with my own eyes. My experience and my Mom’s experience was fantastic. I think every single patient shall see Doctor Bakongwa. They are very human. Thank you Bakongwa ."</span><br />
                                    <i className="green-text">Aurlus Ndunguru - Somalia</i>
                                </div>
                                <div className="col s12 m12 l4">
                                    <span>""I was in bed or in a chair and when I was moved to a chair someone was there. The staff and treatment has been wonderful. It is a lovely atmosphere here with people from all different nationalities working so well together that alone is a wonderful.Much thanks to you Doctor Bakongwa"</span><br />
                                    <i className="green-text">Elton Charles - Mufindi</i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        )
    }
}