import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class Footer extends Component {

    componentDidMount() {
        document.title = 'Bakongwa Doctors'
    }


    render() {
        return (
            <React.Fragment>
                <footer className="page-footer green darken-2 black-text">
                    <div className="row">
                        <div className="col s10 m10 l10 offset-l1 push-s1 offset-m1 body">
                            <div className="row main">
                                <div className="col s12 m12 l3">
                                    <h5 className="white-text"><strong className="white-text">About <strong className="white-text">Us</strong></strong></h5>
                                    <p>Bakongwa Doctors are the Best Traditional African Herbalists from Tanzania, and the Spiritual Healers, who not only deliver to their promises but even heal the unhealed diseases and solve all solutions.</p><br /> 
                                    <i>
                                    BAKONGWA DOCTORS EXERCISES DOCTOR-PATIENT CONFIDENTIALITY; NEITHER PATIENT’S FILE RECORDS, IDENTITY NOR SECRETS WILL BE SHARED WITH A THIRD PARTY OR MADE PUBLIC UNLESS OTHERWISE, PATIENTS OF THEIR OWN VOLITION WISH TO GIVE A TESTIMONY! 
                                    </i>
                                </div>
                                <div className="col s12 m12 l3">
                                    <h5><strong className="white-text">Our <strong className="white-text">Working Hours</strong></strong></h5>
                                    <p> Monday to Saturday</p>
                                    <p>(From 7am to 4pm)</p>
                                    <p>Consultation Fee : <strong> $20 </strong></p>
                                </div>
                                <div className="col s12 m12 l3">
                                    <h5><strong className="white-text">Contact <strong className="white-text">Info</strong></strong></h5>
                                    Phone Number : <a href="https://wa.me://+243990627777" className="black-text">+243 990 627 777.</a><br />
                                    WhatsApp No. : <a href="https://api.whatsapp.com/send?phone=+243 990 627 777" className="black-text">+243 990 627 777.</a><br />
                                    Email : <a href="mailto:bbakongwadoctors@gmail.com" className="black-text">bbakongwadoctors@gmail.com</a>
                                </div>
                                <div className="col s12 m12 l3">
                                    <h5 className="white-text">Quick <strong className="white-text">Links</strong></h5>
                                    <ul>
                                        <li><Link to='/AboutUs' className="black-text">About Us</Link></li>
                                        <li><Link to='/PowerfullSpell' className="black-text">Powerful Spell</Link></li>
                                        <li><Link to='/Magicrings' className="black-text">Magic Rings</Link></li>
                                        <li><Link to='/TraditionalHerbs' className="black-text">Traditional Herbs</Link></li>
                                        <li><Link to='/Appointment' className="black-text">Make an Appointment</Link></li>
                                        <li><Link to='/ContactUs' className="black-text">Contact Us</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="footer-copyright row body">
                                <div className="center">
                                    <p className="black-text">Treatment & Healing services offered within Kenya, Tanzania, Uganda, Rwanda, Burundi, Sudan & Africa. | Contact Number: <a href="https://wa.me://+243990627777" className="black-text">+243 990 627 777</a> | Location: Kenya, Tanzania, Uganda, Rwanda, Burundi, Sudan, DR Congo & Africa.
                                    </p>
                                    <strong className="white-text">
                                        <a href="https://web.facebook.com/Mwinga-Doctors-111010601463275" rel="noopener noreferrer" target="_blank" className="black-text">Connect with us on Facebook
                                        </a>
                                        |
                                        <strong className="black-text"><a className="black-text" href="https://www.instagram.com/mwingadoctors/" target="_blank" rel="noopener noreferrer" > Connect with us on Instagram
                                        </a>
                                        </strong>
                                    </strong>
                                    |
                                    <strong  >
                                        <a href="https://twitter.com/DoctorsMwinga" target="_blank" rel="noopener noreferrer" className="black-text" >Connect with us on Twitter
                                        </a>
                                    </strong>
                                </div>
                            </div>
                            <div className="row">
                                <div className="center">
                                    <p className="white-text"><b>Bakongwa Doctors | © {new Date().getFullYear()} - All Rights Reserved | Developed And Maintained By Winston Evarist.</b></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        )
    }
}