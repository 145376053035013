import React, { Component } from 'react'
import Whatsapp from './components/Whatsaap'
import Navbar from './components/Navbar'
import image76 from './components/Images/image001.jpg'
import Footer from './components/Footer'

export default class BusinessSolution extends Component {

    componentDidMount() {
        document.title = 'Bakongwa Doctors'
    }


    render() {
        return (
            <React.Fragment>
                <Navbar /><br />
                <Whatsapp />
                <div className="row grey lighten-5 row">
                    <div className="col s10 m10 l10 push-l1 push-s1 offset-m1">
                        <img src={image76} alt="image73" className="materialboxed col s12 m12" />
                        <span className="img-tittle green-text text-darken-2"><strong style={{ fontSize: "25px" }}>I found thieves sleeping in my shop due to the protection measures I have in my business..</strong></span>
                        <p>
                            My name is Joe, and at one point, I was robbed in my store, which set me back significantly. This forced me to abruptly close my business due to a lack of funds to sustain it.

                            The incident deeply hurt me, and since I couldn't apprehend the thieves and mete out justice myself, I went to the police station to report the incident in the hope of recovering my stolen goods.

                            It's been a year now, and no one has been caught for the theft. Every time I visit the police station, I am assured that they are close to apprehending the culprits.

                            In the neighborhood of Kibera in Nairobi, where I live, cases of theft have escalated significantly. Every day, there is an incident of theft, whether it involves the use of force or deception.

                            People complain daily about the lack of justice. When I talk about justice, I mean how these criminals are sometimes arrested and charged in court for their acts of thuggery, but within a short period, they are back in the community.

                            I reached a point of frustration and decided to consult a traditional healer, Bakongwa Doctors, to help us combat these theft cases and put an end to them.

                            This decision came after a friend visited me, saw the challenges I faced after closing my business, and told me about Bakongwa Doctors.

                            The experts from Bakongwa Doctors visited the area where I conducted my business, performed their rituals, and assured me that there would be no more theft in that area.

                            A week later, I caught two thieves attempting to steal. I found them disoriented and sleeping in my business premises, and I was able to call the police. They arrived and arrested the thieves. Since that day, there has been no more theft in that area. Thanks so much to Dr Bakongwa..</p>
                        <strong>Connect with Us:<a href="tel:+243 990 627 777" className="green-text text-darken-2" > Phone : +243 990 627 777</a></strong> | <strong><a href="mailto:bbakongwadoctors@gmail.com" className="green-text text-darken-2" >Email :bbakongwadoctors@gmail.com </a></strong>
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        )
    }
}