import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import 'material-icons/iconfont/material-icons.css';
import 'materialize-css/dist/css/materialize.min.css'
import Muringo from './Muringo'
import Spells from './Spells'
import Magicrings from './Magicrings'
import TraditionalHerbs from './TraditionalHerbs'
import Muringonews from './Appointment'
import Aboutus from './AboutUs'
import Contactus from './ContactUs'
import PowerfullSpell from './PowerfullSpell';
import './App.css'
import Appointment from './Appointment'
import Fear from './Fear'
import Drug from './Drug'
import Married from './Married'
import MarriageSolution from './MarriageSolution'
import FinancialSolution from './FinancialSolution'
import EducationSolution from './EducationSolution'
import PenisSolution from './PenisSolution'
import EnemiesSolution from './EnemiesSolution'
import JobSolution from './JobSolution'
import LuckCharmSolution from './LuckCharmSolution'
import LostSolution from './lostSolution'
import WorkRelatedSolution from './WorkRelatedSolution'
import WitchCraftSolution from './WitchCraftSolution'
import BusinessSolution from './BusinessSolution'
import LoveSolution from './LoveSolution'
import CashSolution from './cashSolution'
import Addiction from './addiction'
import Inside from './Inside'
import SexualDesire from './SexualDesire'
import Miscariage from './Miscariage'
import upendoSolution from './upendoSolution'
import Login from './screens/user/login'
import PageNotFound from './screens/404/pageNotFound'
import CustomerView from './screens/user/customerView'
import BlogForm from './screens/blog/blogForm'
import BlogList from './screens/blog/blogList'
import BlogRead from './screens/blog/blogRead'
import BlogView from './screens/blog/blogView'
import Counselling from './counselling';

export default function App() {

  return (
    <React.Fragment>
      <Router>
        <Switch >
          <Route exact path='/' component={Muringo} />
          <Route exact path='/Addiction' component={Addiction} />
          <Route exact path='/SexualDesire' component={SexualDesire} />
          <Route exact path='/Miscariage' component={Miscariage} />
          <Route exact path='/Inside' component={Inside} />
          <Route exact path='/FinancialSolution' component={FinancialSolution} />
          <Route exact path='/CashSolution' component={CashSolution} />
          <Route exact path='/lostSolution' component={LostSolution} />
          <Route exact path='/Spells' component={Spells} />
          <Route exact path='/Magicrings' component={Magicrings} />
          <Route exact path='/TraditionalHerbs' component={TraditionalHerbs} />
          <Route exact path='/Muringonews' component={Muringonews} />
          <Route exact path='/Aboutus' component={Aboutus} />
          <Route exact path='/Contactus' component={Contactus} />
          <Route exact path='/PowerfullSpell' component={PowerfullSpell} />
          <Route exact path='/Appointment' component={Appointment} />
          <Route exact path='/Fear' component={Fear} />
          <Route exact path='/Drug' component={Drug} />
          <Route exact path='/Married' component={Married} />
          <Route exact path='/PenisSolution' component={PenisSolution} />
          <Route exact path='/EducationSolution' component={EducationSolution} />
          <Route exact path='/MarriageSolution' component={MarriageSolution} />
          <Route exact path='/EnemiesSolution' component={EnemiesSolution} />
          <Route exact path='/JobSolution' component={JobSolution} />
          <Route exact path='/LuckCharmSolution' component={LuckCharmSolution} />
          <Route exact path='/WorkRelatedSolution' component={WorkRelatedSolution} />
          <Route exact path='/WitchCraftSolution' component={WitchCraftSolution} />
          <Route path='/counselling' component={Counselling} />
          <Route exact path='/BusinessSolution' component={BusinessSolution} />
          <Route exact path='/upendoSolution' component={upendoSolution} />
          <Route exact path='/LoveSolution' component={LoveSolution} />
          <Route path='/blog/view' component={BlogView} />
          <Route path='/login' component={Login} />
          <Route path='/customer/view' component={CustomerView} />
          <Route path='/blog/form' component={BlogForm} />
          <Route path='/blog/list' component={BlogList} />
          <Route path='/blog/:title' component={BlogRead} />
          <Route path='*' component={PageNotFound} />
        </Switch>
      </Router>
    </React.Fragment>
  )
}