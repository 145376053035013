import React from 'react'
import M from 'materialize-css'
import Loader from '../../components/loader'
import { getUserInfo, servelURL, date } from '../../config'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import backgroundImage from '../../config/logo.jpg'
import Input from '../../components/input'
import Button from '../../components/button'

const CustomerView = function (props) {

    const [loading, setLoading] = React.useState(false)
    const [dataObject, setDataObjcet] = React.useState('')
    const [disabled, setDisabled] = React.useState(false)

    React.useEffect(function () {
        if ((getUserInfo() !== undefined) && props.location.state !== undefined) {
            document.title = 'Account View | Bakongwa Doctors'
            const { account } = props.location.state
            setDataObjcet(account)
        }
        else props.history.push("/blog/list")
        M.AutoInit()
        return function () { }
        // eslint-disable-next-line
    }, [])


    async function deleteAccount() {
        try {
            const condition = JSON.stringify({
                _id: dataObject._id
            })
            const schema = 'user'
            const parameters = `schema=${schema}&condition=${condition}`
            const res = window.confirm(`Are you sure you want to delete ${dataObject.name.toUpperCase()} account ?`)
            if (res) {
                let response = await fetch(`${servelURL}/delete?${parameters}`, {
                    method: 'DELETE',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })

                response = await response.json()
                if (response.success) {
                    setLoading(false)
                    new M.Toast({ html: 'Successfully Deleted Account' })
                    props.history.push('/customer/list')
                }
                else {
                    setDisabled(false)
                    setLoading(false)
                    new M.Toast({ html: response.message })
                }
            }
            else setLoading(false)
        }
        catch (error) {
            setLoading(false)
            if (error instanceof Error) {
                new M.Toast({ html: error.message })
            }
            else console.log(error)
        }
    }

    return (
        <main>
            <Navbar />
            {
                loading ? <Loader /> : null
            }
            <div
                className='background-image'
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundSize: 'cover',
                    opacity: 0.2,
                    zIndex: -1,
                }}
            >
            </div>
            <div className='row' style={{ marginTop: '1rem' }}>
                <div className='col s12 m3 push-m2'>
                    <div className='card'>
                        <div className='card-content'>
                            <span className='card-title center'>Basic Info's</span>
                            <div className='row'>
                                <div className='col s12'>
                                    <p className='black-text'><i className='material-icons left'>account_box</i>
                                        Account Role : {getUserInfo()?.role === 'admin' ? 'Adminstrator ' : 'Customer'}
                                    </p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col s12' >
                                    <p className='black-text'><i className='material-icons left'>person</i>
                                        Full Name : {dataObject?.name?.toUpperCase()}
                                    </p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col s12' >
                                    <p className='black-text'><i className='material-icons left'>add_location</i>
                                        Country : {dataObject?.location?.Country}
                                    </p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col s12' >
                                    <p className='black-text'><i className='material-icons left'>my_location</i>
                                        Region : {dataObject?.location?.Region}
                                    </p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col s12' >
                                    <p className='black-text'><i className='material-icons left'>location_searching</i>
                                        District : {dataObject?.location?.District}
                                    </p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col s12' >
                                    <p className='black-text'><i className='material-icons left'>update</i>
                                        Created By : {dataObject.created_by === null ? dataObject?.name?.toUpperCase() : getUserInfo()?.name?.toUpperCase()}
                                    </p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col s12' >
                                    <p className='black-text'><i className='material-icons left'>update</i>
                                        Updated By : {dataObject.updated_by === null ? 'N/A' : getUserInfo()?.name?.toUpperCase()}
                                    </p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col s12' >
                                    <p className='black-text'><i className='material-icons left'>av_timer</i>
                                        Created Date : {date(dataObject.createdAt)}
                                    </p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col s12' >
                                    <p className='black-text'><i className='material-icons left'>update</i>
                                        Updated Date : {dataObject.createdAt === dataObject.updatedAt ? 'N/A' : date(dataObject.updatedAt)}
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className='col s12 m5 push-m2'>
                    <div className='card'>
                        <div className='card-content'>
                            <span className='card-title center'>Take Action</span>
                            <div className='row'>
                                <div className='col s6'>
                                    <Input
                                        name={dataObject.phone}
                                        label='Phone Number:'
                                        value={dataObject.phone}
                                        readOnly
                                        type='text'
                                        icon='phone_in_talk'
                                    />
                                </div>
                                <div className='col s6'>
                                    <Input
                                        name={dataObject.email}
                                        label='Email Address:'
                                        value={dataObject.email ? dataObject.email : 'N/A'}
                                        readOnly
                                        type='text'
                                        icon='email'
                                    />
                                </div>
                            </div>
                        </div>
                        {
                            getUserInfo()?.role === 'admin' ?
                                <div className='card-action center'>
                                    <div className='row'>
                                        <div className='center'>
                                            <Button
                                                className='btn-small amber lighten-2 black-text '
                                                type='Delete'
                                                icon='delete'
                                                onClick={() => {
                                                    deleteAccount(dataObject._id)
                                                }}
                                                disabled={disabled ? true : true}
                                            />
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className='card-action center'>
                                    <div className='row'>
                                        <div className='col s12'>
                                            <Button
                                                className='btn-small light-green accent-3 black-text'
                                                icon='edit'
                                                name='Update'
                                                onClick={() => {
                                                    props.history.push({
                                                        pathname: '/change/password',
                                                        state: { account: dataObject }
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>
            <div className="fixed-action-btn">
                <a href="/blog/form" className="btn-floating btn-large green">
                    <i className="large material-icons">add</i>
                </a>
            </div>
            <Footer />
        </main>
    )
}
export default CustomerView