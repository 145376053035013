import React, { useState, useEffect } from 'react';

const BlinkingWord = ({ text }) => {
  const [isRed, setIsRed] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsRed((prevIsRed) => !prevIsRed);
    }, 500); // Change the interval duration as desired (in milliseconds)

    return () => clearInterval(interval);
  }, []);

  const color = isRed ? 'red' : 'initial';

  return (
    <span style={{ color }}>
      {text}
    </span>
  );
};

export default BlinkingWord;
